import styled from "styled-components";
import { Button as AntButton, Form } from 'antd';
import React from "react";

export const StyledButton = styled.button`
  margin-bottom: 1.25rem;
  outline: none;
  &:hover {
    color: ${(props) => props.theme.colorPrimary};
  }
`;


export const StyledPrimaryButton = styled(AntButton)`
  width: 100%;
  height: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  text-align: center;
  box-shadow: none;
  background-color: ${(props) => props.theme.colorPrimary};
  border-color: ${(props) => props.theme.colorPrimary};
  &:disabled {
    background-color: ${(props) => props.theme.disabledColor};
    border-color: ${(props) => props.theme.disabledColor};
  }
`;


export const LearnMoreButton = styled(AntButton)`
  border: 0;
  box-shadow: none;
  color: ${(props) => props.theme.colorPrimary};
  &:hover {
    color: ${(props) => props.theme.colorPrimaryHover};
  }
`;


// export const CategoryButton = styled.button`
//   padding: 0.5rem 1rem;
//   margin-top: 0.25rem;
//   border: 2px solid;
//   border-color: ${(props) =>
//     props.selected ? '#E5791B' : 'transparent'};  /* Dynamic border color */
//   font-weight: 600;
//   border-radius: 0.375rem;
//   background-color: ${(props) => (props.selected ? 'white' : 'transparent')};
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: ${(props) => (props.selected ? '#f0f0f0' : '#f9f9f9')};
//   }
// `;

// export const CategoryText = styled.span`
//   color: ${(props) =>
//     props.selected ? '#E5791B' : '#2d3748'};  /* Dynamic text color */
//   font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
//   transition: color 0.3s ease;
// `;
export const CategoryButton = styled.button`
  padding: 0.5rem 1rem;
  margin-top: 0.25rem;
  border: 2px solid;
  border-color: ${(props) =>
    props.selected ? props.theme.colorPrimary : props.theme.borderColor };  /* Using theme color */
  font-weight: 600;
  border-radius: 0.375rem;
  background-color: ${(props) => (props.selected ? 'white' : 'transparent')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) =>
      props.selected ? props.theme.primaryHoverBgColor : props.theme.whiteBgColor};  /* Using theme hover color */
  }
`;


// Styled Text Component
export const CategoryText = styled.span`
  color: ${(props) =>
    props.selected ? props.theme.colorPrimary : props.theme.textDefaultColor};  /* Using theme text colors */
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  transition: color 0.3s ease;
`;


export const StyledSecondaryButton = styled(AntButton)`
  width: 100%;
  height: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  text-align: center;
  box-shadow: none;
  color: ${(props) => props.theme.colorPrimary};
  border-color: ${(props) => props.theme.colorPrimary};
  &:hover {
    color: ${(props) => props.theme.colorPrimaryHover};
    border-color: ${(props) => props.theme.colorPrimaryHover};
  }
`;




const darkTheme = {
  background: '#333',
  color: '#fff',
  inputBackground: '#444',
  inputColor: '#fff',
};

// export const FormItem = styled(Form.Item)`
//   background: ${(props) => props.theme.background};
//   color: ${(props) => props.theme.color};

//   .ant-input {
//     background: ${(props) => props.theme.inputBackground};
//     color: ${(props) => props.theme.inputColor};
//   }
// `;


export const FormItem = (props) => (
  <Form.Item
    {...props}
    style={{
      backgroundColor: '#333',
      color: '#fff',
      padding: '10px',
      borderRadius: '4px',
    }}
  >
    {React.cloneElement(props.children, {
      style: {
        backgroundColor: '#444',
        color: '#fff',
      },
    })}
  </Form.Item>
);
