import axios from "axios";
import { notification } from "antd";
import getToken from "../../../pages/AuthFunc/gcAuthFunction";

export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const SET_QUOTATION_DATA = "SET_QUOTATION_DATA";
export const SET_LOADING = "SET_LOADING";
export const RESET_STORE = "RESET_STORE";

const url = "https://sisos-eu.azurewebsites.net/api/cmd";

export const updateUserDetails = (field, value) => ({
  type: UPDATE_USER_DETAILS,
  payload: { field, value },
});

export const resetStore = () => ({
  type: RESET_STORE,
});

export const setQuotationData = (data) => ({
  type: SET_QUOTATION_DATA,
  payload: data,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const generateQuotation = (contextData) => async (dispatch) => {
  dispatch(setLoading(true));

  const makeRequest = async (token) => {
    try {
      const response = await axios.post(
        url,
        {
          cmd: "ExeChain",
          data: {
            chain: "M3TrainingGroupCreditFixedRating",
            context: JSON.stringify(contextData),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { ok, msg, outData } = response.data;

      if (!ok) {
        notification.error({ message: msg });
      } else {
        dispatch(setQuotationData(outData));
        notification.success({ message: "Quotation generated successfully." });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          // Token expired, get a new token
          const newToken = await getToken();
          // Retry the request with the new token
          await makeRequest(newToken);
        } catch (newTokenError) {
          notification.error({
            message: "Failed to refresh token. Please try again later.",
          });
        }
      } else {
        notification.error({
          message: "An error occurred while processing the quote.",
        });
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const token =
    localStorage.getItem("authenticationToken") || (await getToken());
  await makeRequest(token);
};
