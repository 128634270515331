import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";

const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  const [transactions, setTransactions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const triggerNotification = useNotification();
  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 10,
    requestStatus: "",
    requestType: "",
    excludeTransfers: true,
    searchText: "",
    startDate: "",
    endDate: "",
  });

  const getTransactions = useCallback(async () => {
    try {
      setIsLoading(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject(filter);
      const response = await postAuthenticatedRequest(
        "GetTransferRequestsQuery",
        {
          operation: "GET",
          ...cleanedFilter,
        }
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        setTransactions(result?.data);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch transactions",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification, filter]);

  return (
    <TransactionContext.Provider
      value={{ transactions, isLoading, getTransactions, filter, setFilter }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};
