import React from "react";
import { Modal, Radio, Typography } from "antd";
import { useTheme } from "../../store/context/theme-context";
import { useLanguage } from "../../store/context/language-context";

const { Text } = Typography;

const languages = [
  { code: "en", name: "English" },
  { code: "sw", name: "Swahili" },
  { code: "fr", name: "Français" },
  { code: "rw", name: "Kinyarwanda" },
];

const LanguageSwitcher = () => {
  const { theme } = useTheme();
  const { isModalOpen, handleOkay, handleCancel, language, setLanguage } =
    useLanguage();

  const changeLanguage = (lang) => {
    setLanguage(lang);
    handleOkay();
  };

  const handleLanguageChange = (e) => {
    changeLanguage(e.target.value);
  };

  const handleClick = (lang) => {
    changeLanguage(lang);
  };

  return (
    <Modal
      title={<p className="text-center">Language</p>}
      open={isModalOpen}
      onOk={handleOkay}
      onCancel={handleCancel}
      footer={null}
      className="max-w-sm"
    >
      <>
        <div className="mb-5">
          <p className="text-xl font-semibold mb-1">Choose your language</p>
          <p className="text-[#929497] text-left">
            Chagua lugha / Sélectionnez la langue / Hitamo nururimi
          </p>
        </div>

        <Radio.Group
          onChange={handleLanguageChange}
          value={language}
          style={{ width: "100%" }}
        >
          {languages.map((lang) => (
            <div key={lang.code}>
              <div
                className={`w-full flex items-center justify-between cursor-pointer p-1 ${
                  theme === "dark" ? "hover:bg-stone-900" : "hover:bg-gray-100"
                }`}
                onClick={() => handleClick(lang.code)}
              >
                <Text className="text-[16px] leading-[24px]">{lang.name}</Text>
                <Radio value={lang.code} className="text-xl" />
              </div>
              {lang.code !== languages[languages.length - 1].code && (
                <hr className="mb-2" />
              )}
            </div>
          ))}
        </Radio.Group>
      </>
    </Modal>
  );
};

export default LanguageSwitcher;
