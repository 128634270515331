import React, { createContext, useContext, useCallback } from "react";
import { App } from "antd";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { notification } = App.useApp();
  
  const triggerNotification = useCallback(
    ({ type, message, description , onProceed, onClose}) => {
      // Define the action buttons if provided
    const actionButtons = (
      <div>
        {onProceed && (
          <button
            style={{
              marginRight: "8px",
              border: "none",
              background: "green",
              color: "white",
              padding: "5px 10px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
            onClick={() => {
              if (onProceed) onProceed();
            }}
          >
            Proceed
          </button>
        )}
        {onClose && (
          <button
            style={{
              border: "none",
              background: "red",
              color: "white",
              padding: "5px 10px",
              cursor: "pointer",
              borderRadius: "4px",
            }}
            onClick={() => {
              if (onClose) onClose();
            }}
          >
            Close
          </button>
        )}
      </div>
    );

      notification[type]({
        message,
        description,
        btn: actionButtons, // Add the custom buttons
      });
    },
    [notification]
  );

  return (
    <NotificationContext.Provider value={triggerNotification}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
