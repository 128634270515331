// src/store/context/OrganisationContext.js
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { formatCurrencyWithCode } from "../../../utils/helper/format";
import { Dropdown, Form } from "antd"; import {
  MoreOutlined,
} from "@ant-design/icons";

// Create context
const ProviderContext = createContext();

// Custom hook for accessing context
export const useProviderContext = () => useContext(ProviderContext);

export const ProviderProvider = ({ children }) => {
  const [providers, setProviders] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const triggerNotification = useNotification();

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const [selectedProviderId, setSelectedProviderId] = useState(false);

  const [modalOption, setModalOption] = useState({
    isOpen: false,
    mode: "create",
    selectedRowId: null,
  });


  const resetModalOption = () => {
    setModalOption({
      isOpen: false,
      mode: "edit",
      selectedRowIndex: null,
    });
  };

  // API call function to get the organisation structure
  const getProviders = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await postAuthenticatedRequest(
        "RetrieveServiceProvidersQuery"
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        console.info(result?.data)
        setProviders(result?.data);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification]);


  // API call function to get the organisation structure
  const initializer = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await postAuthenticatedRequest(
        "InitializeServiceProvidersCommand"
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        console.info(result?.data)
        setProviders(result?.data);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification]);


  const transactionType = [

    {
      name: "AIRTIME RECHARGE",
      key: 0
    },
    {
      name: "DATA RECHARGE",
      key: 1
    },
    {
      name: "BILLS PAYMENT",
      key: 2
    },
    {
      name: "TRANSFERS",
      key: 3
    },

    {
      name: "CREDIT SEARCH FIRST",
      key: 4
    },

    {
      name: "CREDIT SEARCH SECOND",
      key: 5
    },

    {
      name: "BANK STATEMENT SERVICE",
      key: 6
    },

    {
      name: "ID VALIDATION SERVICE",
      key: 7
    },

    {
      name: "BVN VALIDATION SERVICE",
      key: 8
    },

  ]
  const availbaleProviders = [
    {
      name: "NIBSS",
      key: 0
    },
    {
      name: "EAZY PAY",
      key: 1
    },
    {
      name: "INTERSWITCH",
      key: 2
    },
    {
      name: "CREDITSWITCH",
      key: 3
    },
    {
      name: "CRC",
      key: 4
    },
    {
      name: "FIRST CENTRAL",
      key: 5
    },
    {
      name: "REMITTA",
      key: 6
    },
    {
      name: "WALLZ AND QUEEN",
      key: 7
    }
  ]



  const columns = [
    {
      title: "Service",
      render: (_, data) => {
        return `${data.selfServiceTransactionTypeDesc}`.replaceAll("_", " ");
      }, dataIndex: ""
    },
    {
      title: "Provider",
      render: (_, data) => {
        return availbaleProviders?.find(
          (item) => item?.key === data.serviceProvider
        )?.name ?? "Not Set";
      },
    },
    {
      title: "Amount Range",
      render: (_, data) => {
        return `${formatCurrencyWithCode(data.minimumAmount
        )} - ${formatCurrencyWithCode(data.maximumAmount)}`
      }
    },

    { title: "Channel Ref.", dataIndex: "channelReference" },
    {
      title: "Status",
      render: (_, data) => {
        return data.serviceEnabled ? 'Enabled' : 'Disabled'
      }
    },
    {
      title: "",
      render: (_, record) => (
        <Dropdown
          menu={{
            items: providerRowOptions,
            onClick: (item) => handleRowClick(record?.id, item.key),
          }}
          trigger={["click"]}
          placement="bottomRight"
        >
          <MoreOutlined />
        </Dropdown>
      ),
    },

  ];

  useEffect(() => {
    if (modalOption.mode === "create") {
      form.resetFields();
    } else if (
      modalOption.mode === "edit" &&
      modalOption.selectedRowId != null
    ) {
      const selectedRowData = providers?.find(
        (item) => item?.id === modalOption.selectedRowId
      );

      console.log("selectedRowData", modalOption.selectedRowId, selectedRowData);
      setSelectedProviderId(selectedRowData?.id);
      form.setFieldsValue({
        serviceProvider: availbaleProviders.find((e) => e.key == selectedRowData?.serviceProvider)?.name ?? null,
        transactionType: transactionType.find((e) => e.key == selectedRowData?.selfServiceTransactionType)?.name ?? null,
        minimumAmount: selectedRowData?.minimumAmount,
        serviceStatus: selectedRowData?.serviceEnabled,
        maximumAmount: selectedRowData?.maximumAmount,
        channelReference: selectedRowData?.channelReference,
      });

    }
  }, [modalOption, providers, form]);

  const providerRowOptions =
    [
      {
        key: "1",
        label: "Edit",
      },
    ]


  const handleRowClick = (rowId, menuKey) => {
    console.log("recorder ---- ", rowId);
    if (menuKey === "1") {

      setModalOption({
        isOpen: true,
        mode: "edit",
        selectedRowId: rowId,
      });
    }
  };
  useEffect(() => {
    if (providers == null) getProviders();
  }, [providers])

  const createProvider = async (data) => {
    try {
      setIsLoading(true);

      const response = await await postAuthenticatedRequest(
        "CreateSelfServiceProviderCommand",
        data
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        resetModalOption();
        providers.push(result.data);
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "New Provider Created", // 
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description:
            result?.message ||
            "Failed to create Provider at this time. Please try again later.",
        });
      }
    } catch (error) {
      console.log("error??/ ", error);
      triggerNotification({
        type: "error",
        message: "Provider Creation Failed",
        description:
          error?.toString() || "An error occurred. Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateProvider = async (data) => {
    try {
      setIsLoading(true);

      const selectedRowData = providers?.find(
        (item) => item?.id === modalOption.selectedRowId
      );
      const response = await postAuthenticatedRequest(
        "UpdateSelfServiceProviderCommand",
        {
          operation: "POST",
          id: selectedProviderId,
          ...data,
          transactionType: selectedRowData?.selfServiceTransactionType
        });
      const result = response?.outData;
      if (result?.isSuccessful) {
        resetModalOption();
        getProviders();
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || " Provider Updated",
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description:
            result?.message ||
            "Failed to update Provider at this time. Please try again later.",
        });
      }
    } catch (error) {
      console.log("error??/ ", error);
      triggerNotification({
        type: "error",
        message: "Provider Update Failed",
        description:
          error?.toString() || "An error occurred. Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ProviderContext.Provider
      value={{
        providers,
        isLoading,
        setModalOption,
        modalOption,
        resetModalOption,
        initializer,
        columns,
        values,
        form,
        getProviders,
        transactionType,
        availbaleProviders,
        createProvider,
        updateProvider
      }}
    >
      {children}
    </ProviderContext.Provider>
  );
};
