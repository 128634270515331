import axios from "axios";


const authUrl = "https://sisos-eu.azurewebsites.net/api/pub/authenticate";
const email = "equityuat\\emmanuel.chalo@equitybank.co.ke";
const clave = "Equity123!!!";

const getToken = async () => {
  try {
    const response = await axios.post(authUrl, { email, clave });

    const { token } = response.data.outData;
    if (token) {
      localStorage.setItem("authenticationToken", token);
      return token;
    } else {
      throw new Error("Token not found in response");
    }
  } catch (error) {
    console.error("Failed to get token:", error);
    throw new Error("Failed to get token");
  }
};

export default getToken;
