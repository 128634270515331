import React from "react";
import { useTranslation } from "react-i18next";

const PendingLoansHeader = () => {
  const { t } = useTranslation();
  return (
    <div
      className='p-0 rounded-md'
      style={{
        width: "335px",
        height: "76px",
      }}
    >
      <div className='mb-2'>
        <p
            className="font-semibold text-lg text-left mb-5"
          >
            {t("My Pending Applications")}
          </p>
      </div>
      <div>
        <h6 className='text-sm font-normal'>
          {t("Kindly view your ongoing Applications")}.
        </h6>
      </div>
    </div>
  );
};

export default PendingLoansHeader;
