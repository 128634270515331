import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  getUserId,
  getSessionData
} from "./localStorageService";
import { SelfUserClassificationEnum } from "../../shared/constants/constants";

const axiosInstance = axios.create({
  baseURL: "https://apitesting.monee.ng/Fintech.CBS.Backend",
  headers: { "Content-Type": "application/json" }, //optional: default headers
});

// set up request interceptor
// if there is an access token, set the Authorization header with the token
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// set up response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const refreshToken = getRefreshToken();
    const userId = getUserId();
    const originalRequest = error?.config;


    console.log("error", error?.response?.status);
    console.log("refreshToken",refreshToken);
    console.log("originalRequest",originalRequest._retry);
    if (error?.response?.status === 401 && !refreshToken) {
      // No refreshToken, redirect to login page
      // No refreshToken, redirect to login page
      const classification = sessionData?.userClassification ;
      localStorage.clear();
      localStorage.setItem("isLoggedIn", false);
      localStorage.setItem("logoutEvent", Date.now());
      var sessionData = getSessionData();
      if(classification === SelfUserClassificationEnum.BACKOFFICE_ADMINISTRATORS.value)
      {
        window.location.href = "/authentication/admin/login";
      }
      else{
        window.location.href = "/authentication/login";
      }
  
      // console.log("No refresh token");
      return Promise.reject(error);
    } else   if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const dataToSend = {
          cmd: "LoginRefreshTokenCommand",
          data: JSON.stringify({
            userId,
            refreshToken,
          }),
        };

        const response = await axiosInstance.post("/api/bpm/cmd", dataToSend);

        const newAccessToken = response?.data?.outData?.accessToken;

        if (newAccessToken) {
          setAccessToken(newAccessToken);
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        } else {
          // If the refresh token is invalid or expired, redirect to login and clear session data
          const classification = sessionData?.userClassification ;
          localStorage.clear();
          localStorage.setItem("isLoggedIn", false);
          localStorage.setItem("logoutEvent", Date.now());
          if(classification === SelfUserClassificationEnum.BACKOFFICE_ADMINISTRATORS.value)
            {
              window.location.href = "/authentication/admin/login";
            }
            else {
              window.location.href = "/authentication/login";
            }
          // console.log("Invalid refresh token");
          return Promise.reject(error);
        }
      } catch (refreshError) {
        // If the refresh token is invalid or expired, redirect to login and clear session data 
        const classification = sessionData?.userClassification ;
        localStorage.clear();
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("logoutEvent", Date.now());
        if(classification === SelfUserClassificationEnum.BACKOFFICE_ADMINISTRATORS.value)
        {
          window.location.href = "/authentication/admin/login";
        }
        else if(classification){
          window.location.href = "/authentication/login";
        }
        // console.log("Refresh token request failed");
        return Promise.reject(refreshError);
      }
    }
    else if (error?.response?.status === 401 ) {
      // If the refresh token is invalid or expired, redirect to login and clear session data
      const classification = sessionData?.userClassification ;
      localStorage.clear();
      localStorage.setItem("isLoggedIn", false);
      localStorage.setItem("logoutEvent", Date.now());
      if(classification === SelfUserClassificationEnum.BACKOFFICE_ADMINISTRATORS.value)
      {
        window.location.href = "/authentication/admin/login";
      }
      else if(classification){
        window.location.href = "/authentication/login";
      }
      else{
        window.location.href = "/authentication/login";
      }
      // console.log("Invalid refresh token");
      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

// export the axios instance
export default axiosInstance;
