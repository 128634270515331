import fetchPoliciesData from "../../../utils/api/requests/fetchPoliciesData";
import {
  decryptData,
  encryptData,
} from "../../../utils/interceptors/localStorageService";

export const FETCH_POLICIES_REQUEST = "FETCH_POLICIES_REQUEST";
export const FETCH_POLICIES_SUCCESS = "FETCH_POLICIES_SUCCESS";
export const FETCH_POLICIES_FAILURE = "FETCH_POLICIES_FAILURE";

export const fetchPoliciesRequest = () => ({
  type: FETCH_POLICIES_REQUEST,
});

export const fetchPoliciesSuccess = (policies) => ({
  type: FETCH_POLICIES_SUCCESS,
  payload: policies,
});

export const fetchPoliciesFailure = (error) => ({
  type: FETCH_POLICIES_FAILURE,
  payload: error,
});

export const fetchPolicies = () => {
  return (dispatch) => {
    const storedEncryptedPolicies = localStorage.getItem("policiesData");
    console.log("storedEncryptedPolicies --- ", storedEncryptedPolicies);
    // if (storedEncryptedPolicies) {
    //   const decryptedPolicies = decryptData(storedEncryptedPolicies);
    //   dispatch(fetchPoliciesSuccess(decryptedPolicies));
    // } else {
      dispatch(fetchPoliciesRequest());
      fetchPoliciesData()
        .then((res) => {
          const policies = processPolicies(res);
          const pendingQuotes = processPending(res);
          const encryptedPolicies = encryptData({ policies, pendingQuotes });
          localStorage.setItem("policiesData", encryptedPolicies);
          dispatch(fetchPoliciesSuccess({ policies, pendingQuotes }));
        })
        .catch((error) => {
          dispatch(fetchPoliciesFailure(error.message));
        });
    // }
  };
};

const processPolicies = (res) => {
  const activePolicyNumbers = new Set(
    [...(res?.upcomingRenewals ?? []), ... (res?.activePolicies ?? [])].map(
      (policy) => policy.policyNumber
    )
  );

  return [
    ...(res?.upcomingRenewals ?? []).map((policy) =>
      categorizePolicy(policy, "renewal")
    ),
    ...(res?.activePolicies ?? []).map((policy) => categorizePolicy(policy, "live")),
    ...(res?.expiredPolicies ?? []).map((policy) => categorizePolicy(policy, "expired")),
    ...(res?.quotes ?? []).map((quote) => categorizePolicy(quote, "quote")),
    ...(res?.depositAccounts ?? []).map((deposit) => categorizePolicy(deposit, "deposit")),
    ...(res?.loanAccounts ?? []).map((loan) => categorizePolicy(loan, "loan")),
  ].map((policy) => {
    let type = "";
    if ((policy.policyName ?? "").includes("Insurance")) type = "insurance";
    else if (policy.category.includes("deposit")) type = "deposits";
    else if (policy.category.includes("loan")) type = "loans";


    return {
      ...policy,
      type: type,
      active: activePolicyNumbers.has(policy.policyNumber),
    };
  });
};


const processPending = (res) => {


  return [
    ...(res.PendingLoanAccounts ?? []).map((policy) => categorizePolicy(policy, "pendingLoans")),

    // ...res.loanAccounts.map((loan) => categorizePolicy(loan, "loan")),
  ];
};

const categorizePolicy = (policy, category) => ({
  ...policy,
  category: category,
  sliderValue: policy.percentageCompletion,
});
