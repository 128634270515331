import { createContext, useContext, useState, useMemo } from "react";

const FormDataContext = createContext({
  formData: {},
  setFormData: () => {},
});

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState({});

  const value = useMemo(() => ({ formData, setFormData }), [formData]);

  return (
    <FormDataContext.Provider value={value}>
      {children}
    </FormDataContext.Provider>
  );
};

export const useFormData = () => useContext(FormDataContext);
