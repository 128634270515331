import React, { useState } from "react";
import { Card, Button } from "antd";
import { NavLink } from "react-router-dom";

import FuneralExpenseModal from "../Funeral Expense/modals/FuneralExpenseModal";
import CriticalIllnessModal from "../Group Critical Illness/CriticalIllnessModal";
import GroupCreditModal from "../GroupCredit/modals/GroupCreditModal";
import GroupLifeModal from "../Group Life/Modals/groupLifeModal";
import Educamodal from "../Education/Educamodal";
import Goalbasedmodal from "../Goal Based/Goalbasedmodal";
import GroupTermLifeModal from "../Group Term Life/GroupTermLifeModal";
import "./Product.css";
import AnnuityModal from "../Annuity/modals/AnnuityModal";
import MotorVehicleModal from "../MotorVehicleInsurance/modals/MotorVehicleModal";
import HealthModal from "../Health Insurance/HealthModal";
import { LearnMoreButton } from "../../shared/common/StyleComponent";
import { useTenant } from "../../store/context/tenant-context";
import { ProductCategoryEnum } from "../../shared/constants/constants";

const { Meta } = Card;

const Product = ({ product, type }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tenant = useTenant();
  const showModal = () => {
    return;
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderModal = () => {
    switch (type) {
      case "funeralexpense":
        return (
          <FuneralExpenseModal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "criticalillness":
        return (
          <CriticalIllnessModal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "grouplife":
        return (
          <GroupLifeModal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "educationcover":
        return (
          <Educamodal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "termlife":
        return (
          <GroupTermLifeModal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "goalbased":
        return (
          <Goalbasedmodal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "groupcredit":
        return (
          <GroupCreditModal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "annuitypolicy":
        return (
          <AnnuityModal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "motorcover":
        return (
          <MotorVehicleModal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      case "healthcover":
        return (
          <HealthModal
            isModalOpen={isModalOpen}
            onOkay={handleOk}
            onCancel={handleCancel}
            product={product}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Card
      bordered={false}
      cover={
        <img
          alt={product.title}
          src={product.image}
          className='h-40 object-cover'
        />
      }
      className={`m-4 card-hover`}
    >
      <Meta
        title={<span>{product.title}</span>}
        description={<span>{product.description}</span>}
      />
      <div className='flex flex-col lg:flex-row justify-start mt-4 gap-1'>
        <Button type='primary' className='border-0 shadow-none'>
          <NavLink to={product.url}>{product.category === ProductCategoryEnum.LOAN.value ?'Get Loan':'Get Cover'}</NavLink>
        </Button>
        <LearnMoreButton
           theme={tenant.theme}
          onClick={showModal}
        >
          Learn More
        </LearnMoreButton>
      </div>

      {renderModal()}
    </Card>
  );
};

export default Product;
