import { Form, Input, Select } from "antd";
import sspFlag from "../../../assets/flags/ssp.png";
import njaFlag from "../../../assets/flags/9ja.png";
import cdfFlag from "../../../assets/flags/cdf.png";
import rwfFlag from "../../../assets/flags/rwf.png";
import kesFlag from "../../../assets/flags/kes.png";
import tzsFlag from "../../../assets/flags/tzs.png";
import ugxFlag from "../../../assets/flags/ugx.png";
import { PhoneAreas } from "../../../utils/data/jsonData";

const { Option } = Select;

const ContactInfo = ({ form, formData, setFormData }) => {
  const handlePhoneAreaChange = (newValue) => {
    const selectedCountry = PhoneAreas.find((area) => area.code === newValue);
    if (selectedCountry) {
      setFormData({
        ...formData,
        phoneArea: newValue,
        country: selectedCountry.country,
      });
    }
  };

  const preventTextInput = (event) => {
    if (!/^[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="mt-4 mb-6">
        <p className="text-2xl font-semibold text-left mb-2">
          Contact Information
        </p>
        {formData?.userType === "Personal" && (
          <p className="text-left text-[#929497]">
            Fill in the correct email address and phone number with which you
            can be contacted.
          </p>
        )}
        {formData?.userType === "Corporate" && (
          <p className="text-left text-[#929497]">
            Fill in the correct email address and phone number with which you
            can be contacted (Use your company email for registration).
          </p>
        )}
      </div>
      <Form form={form} layout="vertical">
        <div className="grid grid-cols-1 gap-1">
          <Form.Item
            label="Email Address"
            name="email"
            validateTrigger={["onBlur"]}
            rules={[
              {
                required: true,
                message: "Please enter your email address.",
              },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input
              placeholder="Enter your email address"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Mobile Number"
            name="phoneNo"
            onKeyPress={preventTextInput}
            rules={[
              {
                required: true,
                message: "Please input a mobile number.",
              },
              {
                len: 11,
                message: "The input must have exactly 11 digits.!",
              },
            ]}
          >
            <Input
              addonBefore={
                <Select
                  style={{ width: 100 }}
                  value={formData.phoneArea}
                  onChange={handlePhoneAreaChange}
                >
                  {PhoneAreas.map((item) => (
                    <Option value={item.code} key={item.code}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span>{item.code}</span>
                        <img
                          src={item.flag}
                          alt={item.country}
                          style={{ width: "20px", marginLeft: "8px" }}
                        />
                      </div>
                    </Option>
                  ))}
                </Select>
              }
              value={formData.phoneNo}
              onChange={(e) =>
                setFormData({ ...formData, phoneNo: e.target.value })
              }
            />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default ContactInfo;
