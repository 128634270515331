import React, { useEffect } from "react";
import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import ProductsList from "../InsuranceProducts/ProductsList";

const { Content } = Layout;

const RecommendedForYou = ({ favProducts }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.startsWith("/dashboard")) {
      const matchedProduct = favProducts.find((prod) =>
        location.pathname.startsWith(`/dashboard/${prod.url}`)
      );

      if (matchedProduct) {
        navigate(`/${matchedProduct.url}`, { replace: true });
      }
    }
  }, [location.pathname, favProducts, navigate]);

  return (
    <Layout className="layout">
      <Content>
        <ProductsList products={favProducts} />
      </Content>
    </Layout>
  );
};

export default RecommendedForYou;
