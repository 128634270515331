import React, { createContext, useState, useContext, useEffect } from "react";

const OtpContext = createContext();

export const OtpProvider = ({ children }) => {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  useEffect(() => {
    let timer;
    if (isResendDisabled && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      if (seconds === 0) {
        setIsResendDisabled(false);
        setSeconds(59);
        setOtp("");
      }
    }
    return () => clearInterval(timer);
  }, [seconds, isResendDisabled]);

  const resetOTP = () => {
    setOtp("");
    setOtpError(null);
    setSeconds(0);
    setIsResendDisabled(false);
  };

  return (
    <OtpContext.Provider
      value={{
        otp,
        setOtp,
        otpError,
        setOtpError,
        seconds,
        setSeconds,
        isResendDisabled,
        setIsResendDisabled,
        resetOTP,
      }}
    >
      {children}
    </OtpContext.Provider>
  );
};

export const useOtp = () => useContext(OtpContext);
