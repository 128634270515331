import { useTheme } from "../../store/context/theme-context";
import classes from "./Loader.module.css";
import { Spin } from "antd";

const LoadingPage = () => {
  const { theme } = useTheme();

  return (
    <div
      className={`${classes.loader} ${
        theme === "dark" ? "bg-stone-900" : "bg-[#fff]"
      }`}
    >
      <Spin className={classes.spinner} size="large" />
    </div>
  );
};

export default LoadingPage;
