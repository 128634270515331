import React from "react";
import Product from "./Product";

const ProductsList = ({ products }) => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ml-[-12px]'>
      {products?.map((product, index) => (
        <Product key={index} product={product} type={product.type} />
      ))}
    </div>
  );
};

export default ProductsList;
