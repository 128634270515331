import React from "react";
import { Progress, Card } from "antd";

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }
    return date.toISOString().split("T")[0];
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid date";
  }
};

const DepositCard = ({ policy, onClick }) => {

  return (
    <div className="pr-4">
      <Card
       className='relative w-[280px] h-[140px] bg-gradient-to-r from-white to-[#FFF8F3] rounded-lg cursor-pointer mb-2'
        onClick={onClick}
      >
        <div>
          <img
            src="/account.svg"
            alt="Vector3"
            className="absolute top-[4.5px] left-0 w-[429.5px] h-[134px] opacity-65"
          />
     



          <div className="flex items-center mb-1 w-full">
            <h5 className="text-base font-bold text-[#221F1F] font-open-sans overflow-hidden whitespace-nowrap text-ellipsis">
              {policy.accountType}
            </h5>

          </div>

          <div className="mb-4 pb-4">
            <p className="text-sm font-normal text-[#221F1F] font-open-sans leading-[16px]">
              {parseFloat(policy.category == 'loan' ? policy.loanAmount : policy.accountBalance).toLocaleString("en-NG", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {policy.currency}
            </p>
          </div>
        </div>



        <div className="flex items-center mt-3 mb-1 w-full">
          <h6 className="text-[#221F1F] font-semibold text-sm font-open-sans overflow-hidden whitespace-nowrap text-ellipsis">
           {/* <span><Clipboard /> </span> */}
            {policy.accountNumber}
          </h6>
          <span
            className={`mx-1 w-[6px] h-[6px] text-center rounded-full font-open-sans text-[14px] leading-[20px] ${
              policy.accountStatus === 'Active' ? 'bg-[#11E500]' : 'bg-[#E50011]'
            }`}
          ></span>

          <p className="text-sm font-semibold text-[#221F1F] font-open-sans">
            {policy.accountStatus}
          </p>
        </div>
      </Card>
    </div>
  );

};

export default DepositCard;
