import {
    FETCH_POLICIES_REQUEST,
    FETCH_POLICIES_SUCCESS,
    FETCH_POLICIES_FAILURE,
  } from "../actions/policyActions";
  
  const initialState = {
    loading: false,
    policies: [],
    error: "",
  };
  
  const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_POLICIES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_POLICIES_SUCCESS:
        return {
          ...state,
          loading: false,
          policies: action.payload,
          error: "",
        };
      case FETCH_POLICIES_FAILURE:
        return {
          ...state,
          loading: false,
          policies: [],
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default paymentReducer;
  