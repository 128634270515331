// enums.js

// VerificationStatusEnum
export const VerificationStatusEnum = {
    PENDING: 0,
    VERIFICATION_FAILED: 1,
    VERIFICATION_SUCCESSFUL: 2,
};

// UsageStatusEnum
export const UsageStatusEnum = {
    PENDING: 0,
    USED: 1,
    DISCARDED: 2,
};

// RequestStatusEnum with descriptions
export const RequestStatusEnum = {
    PENDING_VERIFICATION: {
        value: 0,
        description: "Awaiting Verification",
    },
    PENDING_PROCESSING: {
        value: 1,
        description: "Pending",
    },
    PROCESSING: {
        value: 2,
        description: "Processing",
    },
    PROCESSED: {
        value: 3,
        description: "Processed",
    },
};

// SelfUserClassificationEnum with descriptions
export const SelfUserClassificationEnum = {
    CUSTOMER: {
        value: 0,
        description: "Customer",
    },
    CORPORATE_USERS: {
        value: 1,
        description: "Corporate Users",
    },
    BACKOFFICE_ADMINISTRATORS: {
        value: 2,
        description: "Administrator",
    },
};

export const ProductCategoryEnum = {
    ALL: {
        value: 0,
        description: "All",
    },
    DEPOSIT: {
        value: 1,
        description: "Deposit",
    },
    LOAN: {
        value: 2,
        description: "Loan",
    },
    INSURANCE: {
        value: 3,
        description: "Insurance",
    },
};


// SelfServiceAccountTypeEnum with descriptions
export const SelfServiceAccountTypeEnum = {
    NONE: {
        value: 0,
        description: "Backoffice Users",
    },
    INDIVIDUAL: {
        value: 1,
        description: "Individual",
    },
    CORPORATE: {
        value: 2,
        description: "Corporate",
    },
};


export const featuresEnum = Object.freeze({
    CUSTOMER_AIRTIME_RECHARGE : 0,
    CUSTOMER_DATA_PURCHASE : 1,
    CUSTOMER_BILL : 2,
    CUSTOMER_TRANSFER : 3,
    CUSTOMER_LOAN : 4,
    CUSTOMER_INSURANCE : 5,
    CUSTOMER_SAVINGS : 6,
    CUSTOMER_INVESTMENT : 7,
    CUSTOMER_LIMIT_UPDATE : 8,
    CUSTOMER_ACCOUNT_UPGRADE : 9,

    ADMIN_USER_MANAGEMENT : 2000,
    ADMIN_ROLE_MANAGEMENT : 2001,
    ADMIN_LOAN_REQUESTS : 2002,
    ADMIN_INSURANCE_REQUESTS : 2003,
    ADMIN_SAVINGS_REQUESTS : 2004,
    ADMIN_TRANSFER_REQUESTS : 2005,
    ADMIN_BILL_REQUESTS : 2006,
    ADMIN_AIRTIME_REQUESTS : 2007,
    ADMIN_DATA_REQUESTS : 2008,
    ADMIN_LIMIT : 2009,

})
