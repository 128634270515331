import { Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useTenant } from "../../../store/context/tenant-context";

const ChangeProfileCard = ({ iconOne, title, description, onCardClick }) => {
  const tenant = useTenant(); 
  return (
    <Card
      className="shadow-md rounded-lg w-full h-full cursor-pointer"
      onClick={onCardClick}
    >
      <div className="flex items-center justify-between gap-3">
        <div className="flex items-center justify-between gap-2">
          <div className="flex-shrink-0">
            <div className="bg-[#92949733] rounded-full p-3">{iconOne}</div>
          </div>
          <div className="h-full">
            <p className="font-semibold">{title}</p>
            <p className="text-gray-500 text-sm">{description}</p>
          </div>
        </div>
        <div>
          <RightOutlined style={tenant.theme.iconOutlineStyle} />
        </div>
      </div>
    </Card>
  );
};

export default ChangeProfileCard;
