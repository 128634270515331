import {
  FETCH_POLICIES_REQUEST,
  FETCH_POLICIES_SUCCESS,
  FETCH_POLICIES_FAILURE,
} from "../actions/policyActions";

const initialState = {
  loading: false,
  policies: [],
  pendingQuotes: [],
  error: "",
};

const policyReducer = (state = initialState, action) => {
  console.log("State ---- ", action);
  switch (action.type) {
    case FETCH_POLICIES_REQUEST:
      console.log("FETCH_POLICIES_REQUEST --- "+ !state.loading);
      return {
        ...state,
        loading: true,
      };
    case FETCH_POLICIES_SUCCESS:
      console.error("FETCH_POLICIES_SUCCESS called?? ", action);
      return {
        ...state,
        loading: false,
        policies: action.payload.policies,
        pendingQuotes: action.payload.pendingQuotes,
        error: "",
      };
    case FETCH_POLICIES_FAILURE:
      console.error("FETCH_POLICIES_FAILURE??");
      return {
        ...state,
        loading: false,
        policies: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default policyReducer;
