import { Typography } from "antd";
import { useTheme } from "../../store/context/theme-context";
import LanguageSelector from "../../shared/languages/LanguageSelector";
import { CommentOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import CountrySupportModal from "../../shared/common/CountrySupportModal";
import { useTenant } from "../../store/context/tenant-context";

const { Title, Text } = Typography;

const AuthLayout = ({ children }) => {
  const { theme } = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tenant = useTenant();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleOkay = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    
  }, []);
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // console.log("Image Path:", tenant.logo);
  

  return (
    <>
      {!collapsed && (
        <div className="flex flex-row min-h-screen w-full overflow-hidden">
          {/* Left Side */}
          <div
            className="flex flex-col w-3/5 min-h-screen relative bg-cover bg-center"
            style={{
              backgroundImage: `url(${theme === "dark" ? tenant.loginImageBackgroundDark : tenant.loginImageBackground})`, // Conditional background image
              position: "fixed", // Fixed positioning for the left side
              top: 0, // Stick to the top of the viewport
              bottom: 0, // Take full height of the viewport
              left: 0, // Stick to the left side of the viewport
            }}
          >
          <a
  href="#"
  onClick={(e) => {
    e.preventDefault();
    window.location.replace('/');
  }}
>
  <img
    src= {theme === "dark" ? tenant.darkLogo :  tenant.logo}
    alt="Logo"
    className="absolute top-0 left-0 ml-16 mt-12 w-30 h-20"
  />
</a>
            <div className="text-left ml-16 mt-40">
              <Title level={1} style={{ color: "white" }}>
                Welcome to <br />
                {tenant?.companyName}
              </Title>
              <Text className="text-[20px] text-white">
                {tenant?.tagLine}
              </Text>
            </div>
          </div>

          {/* Right Side */}
          <div
            className={`ml-[60%] w-2/5 min-h-screen overflow-y-auto ${
              theme === "dark" ? "bg-stone-900 text-white" : ""
            } p-4`}
            style={{ paddingBottom: "80px" }}
          >
            {children}

            <div
              className={`fixed bottom-0 w-[40%] p-5 ${
                theme === "dark" ? "bg-stone-900 text-white" : "bg-[#fff]"
              }`}
            >
              <div className="flex flex-row items-center justify-between">
                <div className="w-1/2">
                  <LanguageSelector />
                </div>
                <div
                  className="flex items-center justify-center rounded-full bg-gray-300 p-2 cursor-pointer"
                  onClick={openModal}
                >
                  <CommentOutlined  style={{ color: tenant.theme.colorPrimary }} className="text-2xl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {collapsed && (
        <div
          className={`min-h-screen overflow-y-auto ${
            theme === "dark" ? "bg-stone-900 text-white" : ""
          }`}
          style={{ paddingBottom: "80px" }}
        >
          {children}
          <div
            className={`fixed bottom-0 w-full p-5 ${
              theme === "dark" ? "bg-stone-900 text-white" : "bg-[#fff]"
            }`}
          >
            <div className="flex flex-row items-center justify-between">
              <div className="w-1/2">
                <LanguageSelector />
              </div>
              <div
                className="flex items-center justify-center rounded-full bg-gray-300 p-2 cursor-pointer"
                onClick={openModal}
              >
                <CommentOutlined  style={{ color: tenant.theme.colorPrimary }} className="text-2xl" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal should be available in both views */}
      <CountrySupportModal
        isModalOpen={isModalOpen}
        handleOkay={handleOkay}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default AuthLayout;
