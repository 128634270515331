import React from "react";

const withProvider =
  (Component, Providers = []) =>
  (props) => {
    let WrappedComponent = <Component {...props} />;

    // Apply each provider from the Providers array
    Providers.forEach((Provider) => {
      WrappedComponent = <Provider>{WrappedComponent}</Provider>;
    });

    return WrappedComponent;
  };

export default withProvider;
