import { Form, Input, Select } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useNotification } from "../../../store/context/notification-context";
import { useEffect, useState } from "react";
import { useApiRequests } from "../../../utils/api/https/executor";
import { useTenant } from "../../../store/context/tenant-context";


const { Option } = Select;

const transformApiData = (apiData) => {
  return apiData.map((item, index) => ({
    id: index + 1,
    question: item.question,
    answer: "",
  }));
};

const SecurityInfo = ({ form, formData, setFormData }) => {
  const triggerNotification = useNotification();
  const { securityQuestionQuery } = useApiRequests();
  const [userQuestions, setUserQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
 const tenant = useTenant();

  useEffect(() => {
    const getSecurityQuestions = async () => {
      try {
        setIsLoading(true);
        const response = await securityQuestionQuery(
          { operation: "POST" }
        );

        const result = response?.outData;
        if (result?.isSuccessful) {
          const questions = result?.data;
          const transformedQuestions = transformApiData(questions);
          setUserQuestions(transformedQuestions);
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description: result.message,
          });
        }
      } catch (error) {
        // triggerNotification({
        //   type: "error",
        //   message: "Error",
        //   description: error.message || "Failed to fetch user data",
        // });
      } finally {
        setIsLoading(false);
      }
    };

    if (userQuestions.length < 1) {
      getSecurityQuestions();
    }
  }, [triggerNotification, userQuestions.length]);

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...formData.selectedQuestions];
    updatedQuestions[index].question = value;
    setFormData({ ...formData, selectedQuestions: updatedQuestions });
  };

  const handleAnswerChange = (index, value) => {
    const updatedQuestions = [...formData.selectedQuestions];
    updatedQuestions[index].answer = value;
    setFormData({ ...formData, selectedQuestions: updatedQuestions });
  };

  const availableQuestions = (index) => {
    const selectedQuestionValues = formData.selectedQuestions.map(
      (q) => q.question
    );
    return userQuestions.filter(
      (q) =>
        !selectedQuestionValues.includes(q.question) ||
        q.question === formData.selectedQuestions[index].question
    );
  };

  const validatePassword = (_, value) => {
    if (
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!£$#&*%])[A-Za-z\d@!£$#&*%]{8,}$/.test(
        value
      )
    ) {
      return Promise.resolve();
    }
    return Promise.reject(
      "Password must be at least 8 characters, have upper and lower case letters, one digit (0-9), and one special character (!,£,$,#,&,*,%)."
    );
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The two passwords that you entered do not match!");
    },
  });

  return (
    <>
      <div className="mb-4 mt-6">
        <p className="text-2xl font-semibold text-left mb-2">
          Security Information
        </p>
        <p className="text-left text-[#929497]">
          Choose security questions and answers to protect your account. Also,
          remember to choose a strong password and never share it.
        </p>
      </div>

      {isLoading && (
        <div className="mx-auto my-5">
          <p className="text-center"  style={{ color: tenant.theme.colorPrimary }} >
            Fetching questions please wait...
          </p>
        </div>
      )}

      {!isLoading && (
        <Form form={form} layout="vertical">
          <div className="grid grid-cols-1 gap-1">
            {formData.selectedQuestions.map((item, index) => (
              <div key={index}>
                <Form.Item
                  label={`Security Question ${index + 1}`}
                  name={`securityQuestion${index + 1}`}
                  rules={[
                    {
                      required: true,
                      message: `Please select Security Question ${index + 1}.`,
                    },
                  ]}
                >
                  <Select
                    placeholder={`Select Security Question ${index + 1}`}
                    value={item.question}
                    onChange={(value) => handleQuestionChange(index, value)}
                  >
                    {availableQuestions(index).map((question) => (
                      <Option key={question.id} value={question.question}>
                        {question.question}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={`Answer ${index + 1}`}
                  name={`answer${index + 1}`}
                  rules={[
                    {
                      required: true,
                      message: `Please provide an answer for Question ${
                        index + 1
                      }.`,
                    },
                  ]}
                >
                  <Input
                    placeholder={`Answer for Question ${index + 1}`}
                    value={item.answer}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                  />
                </Form.Item>
              </div>
            ))}
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password.",
                },
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password
                placeholder="Enter your password"
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: tenant.theme.colorPrimary }} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password.",
                },
                validateConfirmPassword,
              ]}
            >
              <Input.Password
                placeholder="Confirm your password"
                iconRender={(visible) =>
                  visible ? (
                    <EyeOutlined style={{ color: tenant.theme.colorPrimary }} />
                  ) : (
                    <EyeInvisibleOutlined />
                  )
                }
                value={formData.confirmPassword}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    confirmPassword: e.target.value,
                  })
                }
              />
            </Form.Item>
          </div>
        </Form>
      )}
    </>
  );
};

export default SecurityInfo;
