import React, { createContext, useContext, useState } from 'react';

const TenantContext = createContext();

export const TenantProvider = ({ children, tenant }) => {
  const [tenantDetails] = useState(tenant);

  return (
    <TenantContext.Provider value={tenantDetails}>
      {children}
    </TenantContext.Provider>
  );
};

export const useTenant = () => useContext(TenantContext);