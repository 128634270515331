import { createContext, useState, useContext, useEffect } from "react";

const ThemeContext = createContext();

const getInitialTheme = () => {
  const savedTheme = localStorage.getItem("theme");
  return savedTheme ? savedTheme : "light";
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(getInitialTheme());

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
  //  setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    //I want to log the theme when setting it
    setTheme((prevTheme) => {
      //console.log("Theme:", prevTheme);
      return prevTheme === "light" ? "dark" : "light";
    });

  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
