import React, { useState, useEffect } from "react";
import { Layout, Divider, Typography, Row, Dropdown, Button , Spin, Skeleton} from "antd";
import { useLocation } from "react-router-dom";
import ProductsList from "../components/InsuranceProducts/ProductsList";
import products from "../utils/data/products";
import { useTranslation } from "react-i18next";
import { useFeatures } from "../store/context/feature-context";
import { useTenant } from "../store/context/tenant-context";
import { CategoryButton, CategoryText } from "../shared/common/StyleComponent";
import { featuresEnum, ProductCategoryEnum } from "../shared/constants/constants";

const { Content } = Layout;

const calculateProductCategories = (findFeature) => {
  const categories = [];
  if (findFeature(featuresEnum.CUSTOMER_INSURANCE)) {
    categories.push({ key: "all", label: "All", category: ProductCategoryEnum.ALL.value });
    categories.push({ key: "medical", label: "Medical Insurance", category: ProductCategoryEnum.INSURANCE.value });
    categories.push({ key: "personal", label: "Personal Insurance", category: ProductCategoryEnum.INSURANCE.value });
    categories.push({ key: "education", label: "Education Insurance", category: ProductCategoryEnum.INSURANCE.value });
    categories.push({ key: "funeral", label: "Funeral Insurance", category: ProductCategoryEnum.INSURANCE.value });
    categories.push({ key: "life", label: "Life Insurance", category: ProductCategoryEnum.INSURANCE.value });
  }
  if (findFeature(featuresEnum.CUSTOMER_LOAN)) {
    categories.push({ key: "loans", label: "Loans", category: ProductCategoryEnum.LOAN.value });
  }
  return categories;
};

const Home = () => {
  const { t } = useTranslation();
  const { findFeature, loading } = useFeatures();
  const tenant = useTenant();
  const location = useLocation();
  const receivedData = location.state;

  const productCategories = calculateProductCategories(findFeature);
  const [selectedCategory, setSelectedCategory] = useState(productCategories[0]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    // Handle resizing
    const handleResize = () => setIsMobile(window.innerWidth < 1200);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Initialize default category based on received data
    const defaultCategory = productCategories.find((cat) => cat.key === receivedData?.default) || productCategories[0];
    setSelectedCategory(defaultCategory);
  }, [productCategories, receivedData?.default]);

  const filteredProducts = selectedCategory?.category === ProductCategoryEnum.ALL.value
    ? products
    : products.filter((product) => product.key === selectedCategory?.key);

  const menuItems = productCategories.map((category) => ({
    key: category.key,
    label: category.label,
    onClick: () => setSelectedCategory(category),
  }));

  return (
    <Layout className="layout p-4">
       
      <Row gutter={[16, 16]}>
        <div className="mt-1">
          <p className="text-xl font-bold">{t("Apply for loans")}</p>
          <p className="text-base">
            {t(
              "We offer affordable payroll loan, payday loan, SMEs loan, and Proof of fund loans that protect you and your loved ones against financial loss."
            )}
          </p>
        </div>
      </Row>
      {loading ? (
      
      <Skeleton
      style={{ marginTop: "50px" }}
        active
        paragraph={{
          rows: 5, 
        }}
      />
    ) : 

      <Content>
        <div className="ml-1 mt-4 sm:mt-6 md:mt-8 lg:mt-4 xl:mt-4">
          {isMobile ? (
            <Dropdown menu={{ items: menuItems }} trigger={["click"]} className="w-full font-semibold">
              <Button className="w-full" type="default">
                {selectedCategory?.label || "Select Category"}
              </Button>
            </Dropdown>
          ) : (
            <div className="flex items-center flex-wrap space-x-2">
              {productCategories.map((category) => (
                <CategoryButton
                  key={category.key}
                  theme={tenant.theme}
                  selected={selectedCategory?.key === category.key}
                  onClick={() => setSelectedCategory(category)}
                >
                  <CategoryText theme={tenant.theme} selected={selectedCategory?.key === category.key}>
                    {category.label}
                  </CategoryText>
                </CategoryButton>
              ))}
            </div>
          )}
        </div>
        <Divider className="bg-[#D3D4D5]" />
        <ProductsList products={filteredProducts} />
      </Content>
}
    </Layout>
  );
};

export default Home;
