import { Form, Input } from "antd";

const CompanyInfo = ({ form, formData, setFormData }) => {
  return (
    <>
      <div className="mt-4 mb-6">
        <p className="text-2xl font-semibold text-left mb-2">
          Enter company information
        </p>
        <p className="text-left text-[#929497]">
          Please input the following company details. Confirm that the details
          are correct before proceeding.
        </p>
      </div>

      <Form form={form} layout="vertical">
        <div className="grid grid-cols-1 gap-1">
          <Form.Item
            label="Company Name"
            name="companyName"
            rules={[
              {
                required: true,
                message: "Please enter the company name.",
              },
            ]}
          >
            <Input
              placeholder="Enter the company name"
              value={formData.companyName}
              onChange={(e) =>
                setFormData({ ...formData, companyName: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Company Address"
            name="companyAddress"
            rules={[
              {
                required: true,
                message: "Please enter the company address.",
              },
            ]}
          >
            <Input
              placeholder="Enter the company address"
              value={formData.companyAddress}
              onChange={(e) =>
                setFormData({ ...formData, companyAddress: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Registration Number"
            name="companyRegistrationNumber"
            rules={[
              {
                required: true,
                message: "Please enter the company registration number.",
              },
            ]}
          >
            <Input
              placeholder="Enter the company registration number"
              value={formData.companyRegistrationNumber}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  companyRegistrationNumber: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item
            label="Tax Pin Number"
            name="companyTaxNumber"
            rules={[
              {
                required: true,
                message: "Please enter the tax pin number.",
              },
            ]}
          >
            <Input
              placeholder="Enter the tax pin number"
              value={formData.companyTaxNumber}
              onChange={(e) =>
                setFormData({ ...formData, companyTaxNumber: e.target.value })
              }
            />
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default CompanyInfo;
