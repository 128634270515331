import { Button, Form } from "antd";
import { useEffect, useState } from "react";
import { useTheme } from "../../../store/context/theme-context";
import OtpInput from "react-otp-input";
import { useApiRequests } from "../../../utils/api/https/executor";
import { useNotification } from "../../../store/context/notification-context";
import { useFormData } from "../../../store/context/form-data-context";
import { useTenant } from "../../../store/context/tenant-context";

const containerStyle = {
  display: "flex",
  justifyContent: "start",
  gap: "8px",
};

const inputStyle = {
  width: "30px",
  height: "30px",
  fontSize: "14px",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "#ccc",
  borderRadius: "4px",
  textAlign: "center",
  outline: "none",
  transition: "border-color 0.3s",
};

const ContactVerify = ({ form, current, setCurrent, formData }) => {
  const { theme } = useTheme();
  const triggerNotification = useNotification();
  const isDarkMode = theme === "dark";
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [focusedInput, setFocusedInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const {resendOTP, validateOTP } =  useApiRequests();
  const { formData: signUpContext } = useFormData();
  const { userId, requestId } = signUpContext;

const tenant = useTenant();
  
const inputFocusStyle = {
  ...inputStyle,
  borderColor: tenant.theme.colorPrimary,
  boxShadow: "0 0 5px rgba(163, 42, 41, 0.5)",
};

const darkInputStyle = {
  ...inputStyle,
  borderColor: "#555",
  backgroundColor: "#333",
  color: "#fff",
};

const darkInputFocusStyle = {
  ...darkInputStyle,
  borderColor: tenant.theme.colorPrimary,
  boxShadow: "0 0 5px rgba(163, 42, 41, 0.5)",
};


  useEffect(() => {
    let timer;
    if (isResendDisabled && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else {
      if (seconds === 0) {
        setIsResendDisabled(false);
        setSeconds(59);
        setOtp("");
      }
    }
    return () => clearInterval(timer);
  }, [seconds, isResendDisabled]);

  const handleResend = async () => {
    setOtpError(null);
    setOtp("");
    setSeconds(59);
    setIsResendDisabled(true);

    try {
      const dataToPost = { userId, requestId };
      const response = await resendOTP(dataToPost);
      const result = response?.outData;

      if (result?.isSuccessful) {
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "OTP has been resent successfully.",
        });
      } else {
        triggerNotification({
          type: "error",
          message: "Verification Error",
          description:
            result?.message || "Could not resend OTP. Please try again later",
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description:
          error?.message || "An error occurred. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePhone = () => {
    setCurrent(current - 1);
  };

  const handleVerify = async () => {
    if (otp && otp.length === 6) {
      setLoading(true);
      try {
        const dataToPost = { userId, requestId, otp };
        const response = await validateOTP(dataToPost);
        const result = response?.outData;

        if (result?.isSuccessful) {
          triggerNotification({
            type: "success",
            message: "Success",
            description: result?.message || "OTP verified successfully",
          });
          setOtpError(null);
          setCurrent(current + 1);
        } else {
          triggerNotification({
            type: "error",
            message: "Error",
            description:
              result?.message ||
              "Could not verify OTP. Please try again later.",
          });
        }
      } catch (error) {
        // triggerNotification({
        //   type: "error",
        //   message: "Error",
        //   description:
        //     error?.message || "An error occurred. Please try again later",
        // });
      } finally {
        setLoading(false);
      }
    } else {
      setOtpError("❌ Wrong OTP. Please check again.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-3">
      <div className="mt-4 mb-6">
        <p className="text-2xl font-semibold text-left mb-3">
          Customer Verification
        </p>
        <p className="text-lg font-semibold">
          We have sent a verification code to <br />
          {formData && formData.email
            ? `${formData.email.slice(0, 2)}******${formData.email.slice(
              formData.email.indexOf("@") - 2
            )}`
            : "your email address."}
        </p>
        <p className="text-left text-[#929497] mb-5">
          Please enter the code below.
        </p>

        <div
          style={{ maxWidth: "150px", margin: "0 auto", textAlign: "center" }}
        >
          <img src={"/2fa.svg"} alt="Verification" style={{ width: "100%" }} />
        </div>
      </div>

      <Form form={form} layout="vertical">
        <div className="mt-4 flex justify-center">
          <Form.Item
            label=""
            name="otp"
            rules={[
              {
                required: true,
                message: "Please enter the OTP.",
              },
            ]}
          >
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  onFocus={() => setFocusedInput(index)}
                  onBlur={() => setFocusedInput(null)}
                  style={
                    focusedInput === index
                      ? isDarkMode
                        ? darkInputFocusStyle
                        : inputFocusStyle
                      : isDarkMode
                        ? darkInputStyle
                        : inputStyle
                  }
                />
              )}
              containerStyle={containerStyle}
            />
          </Form.Item>
        </div>

        <p className="text-red-800 my-3 text-center">{otpError}</p>
        <div className="flex flex-col md:flex-row gap-1 mb-4">
          <Button
            type="primary"
            htmlType="submit"
            className="w-full shadow-none text-base h-full px-3 py-2"
            onClick={handleVerify}
            disabled={loading}
          >
            {loading ? "Submitting..." : "Verify"}
          </Button>
          <Button
            type="default"
            onClick={handleResend}
            disabled={isResendDisabled}
            className="px-3 py-2 w-full h-full text-base"
          >
            {isResendDisabled
              ? ` Resend in 00:${seconds < 10 ? `0${seconds}` : seconds}`
              : "Resend"}
          </Button>
        </div>
        <Button
          className="px-3 py-2 w-full h-full text-base"
          onClick={handleChangePhone}
        >
          Change the mobile number
        </Button>
      </Form>
    </div>
  );
};

export default ContactVerify;
