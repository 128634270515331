import { Modal, Button, Row } from "antd";
import healthImg from "../../assets/healthImg.jpg";
import { NavLink } from "react-router-dom";

const HealthModal = ({ isModalOpen, onCancel, onOkay, product }) => {
  const customTitle = (
    <p className="text-lg font-medium text-center font-roboto text-black">
      Health Insurance Cover
    </p>
  );

  return (
    <Modal
      title={customTitle}
      open={isModalOpen}
      onOk={onOkay}
      onCancel={onCancel}
      style={{
        width: "741px",
        height: "710px",
        gap: "0px",
        opacity: "1",
      }}
      footer={[
        <Row justify="start" key="footer-row">
          <Button
            key="close"
            type="primary"
            className="shadow-none font-semibold text-lg leading-6 text-center text-white w-[177px] h-[48px] p-[12px_48px] gap-0 rounded-tl-lg"
          >
            <NavLink to="personal-customer">Apply Now</NavLink>
          </Button>
        </Row>
      ]}
    >
      <div className="flex flex-col gap-1">
        <img
          src={healthImg}
          alt={product.title}
          className="object-cover text-center rounded-[16px] h-[232px] w-[311px] m-auto"
        />
        <div className="py-3 px-0">
          <p className="text-left text-[#221F1F] font-semibold text-[16px] leading-6">
            Health Insurance for Your Peace of Mind
          </p>
          <p className="text-left font-normal text-[#929497] text-base leading-5 mb-4">
            Health insurance is essential for covering medical expenses arising
            from illnesses, injuries, and other health conditions. It ensures
            that you and your family have access to quality healthcare without
            the burden of high out-of-pocket costs.
          </p>
        </div>
        <div className="py-3 px-0">
          <p className="text-left text-[#221F1F] font-semibold text-[16px] leading-6">
            Comprehensive Coverage for All Your Health Needs
          </p>
          <p className="text-left font-normal text-[#929497] text-base leading-5 mb-4">
            Our health insurance plans offer extensive coverage for a wide range
            of medical services, including hospitalization, outpatient care,
            prescription drugs, and preventive services. Choose a plan that
            suits your needs and ensures you get the best care when you need it.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default HealthModal;
