// src/store/context/ProductContext.js
import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";

// Create context
const ProductContext = createContext();

// Custom hook to use the Product context
export const useProductContext = () => useContext(ProductContext);

export const ProductConfigurationProvider = ({ children }) => {
  const triggerNotification = useNotification();
  const [configuredProducts, setConfiguredProducts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [modalOption, setModalOption] = useState({
    isOpen: false,
    mode: "create",
    selectedRowId: null,
  });

  const statusOption = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "active",
      label: "Active",
    },
    {
      value: "inactive",
      label: "Inactive",
    },
  ];

  // Set create/edit modal to default
  const resetModalOption = () => {
    setModalOption({
      isOpen: false,
      mode: "create",
      selectedRowId: null,
    });
  };
  const getConfiguredProducts = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await postAuthenticatedRequest(
        "GetSelfServiceLoanProductsQuery"
        // {
        // filters: filter,
        // pageIndex: 1,
        // pageSize: pageSize,
        // status: status,
        // startDate: startDate.format("YYYY-MM-DD"),
        // endDate: endDate.format("YYYY-MM-DD"),
        // pendingMyApproval: 0,
        // }
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        const transformedData = result?.data.map((product) => ({
          id: product?.id,
          name: product?.name,
          code: product?.code,
          currencyCode: product?.currencyCode,
          interestRateCalculation: product?.interestRateCalculationPeriod,
          interestRateCalculationDescription:
            product?.interestRateCalculationDescription,
          interestRate: product?.interestRate,
          disbursementFeeCalculation: product?.disbursementFeeCalculation,
          disbursementFeeCalculationDescription:
            product?.disbursementFeeCalculationDescription,
          disbursementFee: product?.disbursementFee,
          minimumAmount: product?.minimumAmount,
          maximumAmount: product?.maximumAmount,
          maximumTenorInDays: product?.maximumTenorInDays,
        }));
        setConfiguredProducts(transformedData);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    triggerNotification,
    // startDate,
    // endDate,
    // filter,
    // status,
    // pageIndex,
    // pageSize,
  ]);

  return (
    <ProductContext.Provider
      value={{
        configuredProducts,
        isLoading,
        modalOption,
        setModalOption,
        resetModalOption,
        getConfiguredProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};
