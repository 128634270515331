import React, { createContext, useContext, useEffect, useState } from 'react';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { notification } from 'antd';

import { getAccessToken, getSessionData } from '../../utils/interceptors/localStorageService';

const WebSocketContext = createContext(null);

const URL = 'https://apitesting.borderlesspay.co.uk/Fintech.CBS.Backend/notificationHub';



export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export const WebSocketProvider = ({ children }) => {
  // Initialize with null and then update after checking session
  const [connection, setConnection] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize access token
  useEffect(() => {
    const initializeToken = () => {
      const sessionData = getSessionData();
      if (sessionData?.accessToken) {
        setAccessToken(sessionData.accessToken);
      }
      if (sessionData?.accessToken) {
        setUserId(sessionData.userId);
      }
    };

    initializeToken();
    setIsInitialized(true);
  }, []);

  const handleMessage = (messageRes) => {
    const { messageType, data, action, loanRequestId, message } = messageRes;
    // console.log("message ---- ", message);

    if (action === 'initiated') {
      notification.info({
        message: `Transaction ${action}`,
        description: message,
        placement: 'topRight',
      });
    } else if (messageType === 'TransactionUpdate') {
      notification.info({
        message: `Transaction ${data.status}`,
        description: `Your transaction was ${data.status}.`,
        placement: 'topRight',
      });
    } else if (messageType === 'ApprovalRequired') {
      notification.info({
        message: 'Approval Required',
        description: `A loan request requires your approval. Loan Request ID: ${loanRequestId}`,
        placement: 'topRight',
        btn: (
          <button
            onClick={() => console.log(`Viewing Loan Request ID: ${loanRequestId}`)}
            className="px-4 py-2 text-sm text-blue-600 hover:text-blue-800"
          >
            View Loan Request
          </button>
        ),
      });
    }
  };

  const startConnection = async () => {
    // Don't attempt connection if there's no access token
    if (!accessToken) {
      // console.log('No access token available, skipping connection');
      return;
    }

    try {
      if (connection) {
        await connection.stop();
      }

      const newConnection = new HubConnectionBuilder()
        .withUrl(URL, {
          accessTokenFactory: () => accessToken,
          transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling
        })
        .configureLogging(LogLevel.Information)
        .build();

      newConnection.on('ReceiveNotification', handleMessage);
      newConnection.on('receivetransactionstatus', handleMessage);

      await newConnection.start();
      // console.log('SignalR connection established');
      setConnection(newConnection);

    } catch (error) {
      console.error('SignalR Connection Error:', error);
      // Only retry if we still have an access token
      if (accessToken) {
        setTimeout(startConnection, 5000);
      }
    }
  };

  // Effect to monitor access token changes
  useEffect(() => {
    const checkAccessToken = () => {
      const sessionData = getSessionData();
      const currentToken = sessionData?.accessToken || null;
      const currentUserId = sessionData?.userId || null;

      if (currentToken !== accessToken) {
        setAccessToken(currentToken);
      }
      if (currentUserId !== userId) {
        setUserId(currentUserId);
      }
    };

    // Check for token changes every minute
    const tokenCheckInterval = setInterval(checkAccessToken, 60000);

    return () => {
      clearInterval(tokenCheckInterval);
    };
  }, [accessToken,userId]);

  // Effect to manage connection based on access token
  useEffect(() => {
    if (isInitialized && accessToken && userId) {
      startConnection();
    } else if (!accessToken && connection) {
      // Disconnect if token is removed
      connection.stop();
      setConnection(null);
    }

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [accessToken, userId, isInitialized]);

  const value = {
    isConnected: connection?.state === 'Connected',
    connection,
    isInitialized,
    hasToken: !!accessToken
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};