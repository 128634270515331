import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { decryptData, encryptData, getSessionData, setSessionData } from '../../utils/interceptors/localStorageService';
import { postAuthenticatedRequest } from '../../utils/api/requests/request';
import { useNotification } from './notification-context';
import fetchPoliciesData from '../../utils/api/requests/fetchPoliciesData';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
    const [selectedFilter, setSelectedFilter] = useState('deposits');
    const [savedSessionData, setSavedSessionData] = useState(null);
    const [depositPolicies, setDepositPolicies] = useState([]);
    const [policies, setPolicies] = useState([]);
    const [pendingQuotes, setPendingQuotes] = useState([]);
    const [error, setError] = useState("");
    const [started, setStarted] = useState(false);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const triggerNotification = useNotification();
    const navigate = useNavigate();

    // Fetch session data from localStorage
    useEffect(() => {
        const storedSessionData = getSessionData();
        if (storedSessionData) {
            setSavedSessionData(storedSessionData);
        }
    }, []);

    // Fetch policies on mount or when policies change
    useEffect(() => {
        if (policies.length > 0) {
            const filteredDeposits = policies.filter(policy => policy.type === 'deposits');
            setDepositPolicies(filteredDeposits);
        }
    }, [policies, error, started]);

    // Handle fetching policies from storage or API
    const fetchPolicies = async () => {
        if (started) return; // Prevent multiple simultaneous calls

        try {
            setStarted(true);
            setLoading(true);
            setError("");
            const storedEncryptedPolicies = localStorage.getItem('policiesData');

            if (storedEncryptedPolicies) {
                const decryptedPolicies = decryptData(storedEncryptedPolicies);
                setPolicies(decryptedPolicies.policies);
                setPendingQuotes(decryptedPolicies.pendingQuotes);

            } else {

                const res = await fetchPoliciesData();
                const policies = processPolicies(res);
                const pendingQuotes = processPending(res);
                const encryptedPolicies = encryptData({ policies, pendingQuotes });

                localStorage.setItem('policiesData', encryptedPolicies);
                console.log("policies... ", policies);
                console.log("pendingQuotes... ", pendingQuotes);
                setPolicies(policies);
                setPendingQuotes(pendingQuotes);
            }
        } catch (err) {
            setError(err.message);
            setPolicies([]);
            setPendingQuotes([]);

        } finally {
            setLoading(false);
            setStarted(false);
        }
    };

    const getUserData = async () => {
        try {
            const sessionData = getSessionData();
            const response = await postAuthenticatedRequest(
                "UserProfileQuery",
                { operation: "POST" }
            );

            const result = response?.outData;
            if (result?.isSuccessful) {
                const userData = result?.data;
                const newSessionData = {
                    ...sessionData,
                    ...userData,
                    mobileCountryCode: userData.mobileCountryCode,
                    mobileNumber: userData.mobileNumber,
                    emailAddress: userData.emailAddress,
                };
                setSessionData(newSessionData);
                setSavedSessionData(newSessionData);
            } else {
                triggerNotification({
                    type: "error",
                    message: "Error",
                    description: result.message,
                });
            }
        } catch (error) {
            triggerNotification({
                type: "error",
                message: "Error",
                description: error.message || "Failed to fetch user data",
            });
        }
    };


    // Process policies data to categorize and add types
    const processPolicies = (res) => {
        const activePolicyNumbers = new Set(
            [...(res?.upcomingRenewals ?? []), ... (res?.activePolicies ?? [])].map(
                (policy) => policy.policyNumber
            )
        );

        return [
            ...(res?.upcomingRenewals ?? []).map((policy) =>
                categorizePolicy(policy, "renewal")
            ),
            ...(res?.activePolicies ?? []).map((policy) => categorizePolicy(policy, "live")),
            ...(res?.expiredPolicies ?? []).map((policy) => categorizePolicy(policy, "expired")),
            ...(res?.quotes ?? []).map((quote) => categorizePolicy(quote, "quote")),
            ...(res?.depositAccounts ?? []).map((deposit) => categorizePolicy(deposit, "deposit")),
            ...(res?.loanAccounts ?? []).map((loan) => categorizePolicy(loan, "loan")),
        ].map((policy) => {
            let type = "";
            if ((policy.policyName ?? "").includes("Insurance")) type = "insurance";
            else if ((policy.category ?? "").includes("deposit")) type = "deposits";
            else if ((policy.category ?? "").includes("loan")) type = "loans";


            return {
                ...policy,
                type: type,
                active: activePolicyNumbers.has(policy.policyNumber),
            };
        });
    };


    // Helper function to categorize policies
    const categorizePolicy = (policy, category) => ({
        ...policy,
        category: category,
        sliderValue: policy.percentageCompletion,
    });


    const processPending = (res) => {
        return [
            ...(res.pendingLoanAccounts ?? []).map((policy) => categorizePolicy(policy, "pendingLoans")),

            // ...res.loanAccounts.map((loan) => categorizePolicy(loan, "loan")),
        ];
    };


    return (
        <DashboardContext.Provider value={{
            selectedFilter,
            setSelectedFilter,
            savedSessionData,
            depositPolicies,
            policies,
            pendingQuotes,
            getUserData,
            loading,
            setStarted,
            error,
            fetchPolicies
        }}>
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboard = () => {
    const context = React.useContext(DashboardContext);
    if (context === undefined) {
        throw new Error('useDashboard must be used within a DashboardProvider');
    }
    return context;
}