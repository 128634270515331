import React from "react";
import { Progress, Card } from "antd";
import { useTenant } from "../../store/context/tenant-context";

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date");
    }
    return date.toISOString().split("T")[0];
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid date";
  }
};

const PolicyCard = ({ policy, onClick }) => {
  const isMotorInsurance = policy.type === "motor";
  const tenant = useTenant();

  return (
    <div className='pr-4'>
      <Card
        className='relative w-[280px] h-[160px] bg-gradient-to-r from-white to-[#FFF8F3] rounded-lg cursor-pointer mb-2'
        style={{ boxShadow: "rgba(0, 0, 0, 0.08) 0px 6px 16px -8px" }}
        onClick={onClick}
      >
        {/* <img
          src='/vector1.png'
          alt='Vector1'
          className='absolute top-[25px] left-[-1px] w-[229.5px] h-[134px] opacity-30'
        /> */}
        {/* <img
          src='/vector2.png'
          alt='Vector2'
          className='absolute top-[70px] left-0 w-[429.5px] h-[90px] opacity-30'
        /> */}
        
        <img
          src='/vector3.png'
          alt='Vector3'
          className='absolute top-[4.5px] left-0 w-[429.5px] h-[155.5px] opacity-35'
        />

        <div className='flex items-center mb-1 w-full'>
          <h5 className='text-base font-bold text-[#221F1F] font-open-sans overflow-hidden whitespace-nowrap text-ellipsis'>
            {isMotorInsurance
              ? "Motor Insurance"
              : `${
                  policy.type.charAt(0).toUpperCase() + policy.type.slice(1)
                } Insurance`}
          </h5>
          <span style={{ backgroundColor: tenant.theme.colorPrimary }} className='mx-1 w-[6px] h-[6px]  text-center font-open-sans text-[14px] leading-[20px] rounded-full'></span>
          <p className='text-base font-semibold text-[#e5791b] font-open-sans leading-[0px]'>
            {policy.policyNumber}
          </p>
        </div>
        <div className='mb-4'>
          <p className='text-xs font-normal text-[#221F1F] font-open-sans leading-[16px]'>
            Total Premium:{" "}
            <span>
              {parseFloat(policy.totalPremium).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              KES
            </span>
          </p>
        </div>
        <div className='flex justify-between items-center mb-1'>
          <p className='text-xs text-[#757679] font-open-sans leading-[16px]'>
            Expires in {policy.expiryInDays} days
          </p>
          <p className='text-xs text-[#E5791B] font-open-sans leading-[16px]'>
            {policy.sliderValue}%
          </p>
        </div>
        <Progress
          percent={policy.sliderValue}
          strokeColor='#E5791B'
          size={[230, 6]}
          showInfo={false}
          trailColor='#FFFFFF'
        />
        <div className='mt-1'>
          <p className='text-xs font-normal text-[#221F1F] font-open-sans leading-[16px]'>
            Cover expires on{" "}
            <span className='text-[#E5791B]'>
              {formatDate(policy.policyExpiryDate)}
            </span>
          </p>
        </div>
      </Card>
    </div>
  );
};

export default PolicyCard;
