import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";
import { useApiRequests } from "../../../utils/api/https/executor";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const triggerNotification = useNotification();

  const [selectedUser, setSelectedUser] = useState(null);

  const [isAddUserRoleLoading, setIsAddUserRoleLoading] = useState(false);
  const [isAddUserBranchLoading, setIsAddUserBranchLoading] = useState(false);
  const [isRemoveUserBranchLoading, setIsRemoveUserBranchLoading] =
    useState(false);
  const [isRemoveUserRoleLoading, setIsRemoveUserRoleLoading] = useState(false);
  const [isAllUserLoading, setIsAllUserLoading] = useState(false);
  const [isChangingUserStatus, setIsChangingUserStatus] = useState(false);
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 50,
    status: "",
    customerType: null,
    searchText: "",
    startDate: "",
    endDate: "",
  });
  const [verifiedUsers, setVerifiedUsers] = useState(null);
  const [pendingUsers, setPendingUsers] = useState(null);

  const [allUserRolesQuery, setAllUserRolesQuery] = useState(null);
  const [approvalInformation, setApprovalInformation] = useState(null);

  const [corporateProfile, setCorporateProfile] = useState(null);

  const [activeModal, setActiveModal] = useState(null);

  // const [] = useApiRequests();
  const apiRequests = useApiRequests();
  const openModal = (modalName) => {
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  const fetchActiveUsers = async (data) => {
    setIsAllUserLoading(true); // Show loading indicator

    try {
      // Remove undefined or null values for roleId and branchId
      const requestData = { ...data };
      if (!requestData.roleId) delete requestData.roleId;
      if (!requestData.branchId) delete requestData.branchId;

      // Fetch pending users from the API
      const users = await apiRequests.corporateUsersVerifications(requestData);

      // Format the response
      const formattedUsers = users.map((user) => ({
        name: `${user.firstName} ${user.middleName || ""} ${
          user.lastName
        }`.trim(),
        phone: `${user.mobileCountryCode}${user.mobileNumber}`,
        ...user,
      }));

      // Update state with formatted users
      setVerifiedUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching pending users:", error);
      // Optionally, you can set an error state here if needed
    } finally {
      setIsAllUserLoading(false); // Always turn off loading indicator
    }
  };

  const fetchPendingUsers = async (data) => {
    setIsAllUserLoading(true); // Show loading indicator

    try {
      // Remove undefined or null values for roleId and branchId
      const requestData = { ...data };
      if (!requestData.roleId) delete requestData.roleId;
      if (!requestData.branchId) delete requestData.branchId;

      // Fetch pending users from the API
      const users = await apiRequests.corporateUsersPendingVerifications(
        requestData
      );

      // Format the response
      const formattedUsers = users.map((user) => ({
        name: `${user.firstName} ${user.middleName || ""} ${
          user.lastName
        }`.trim(),
        phone: `${user.mobileCountryCode}${user.mobileNumber}`,
        ...user,
      }));

      // Update state with formatted users
      setPendingUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching pending users:", error);
      // Optionally, you can set an error state here if needed
    } finally {
      setIsAllUserLoading(false); // Always turn off loading indicator
    }
  };

  const addUserToRole = async (roleId) => {
    try {
      setIsAddUserRoleLoading(true); // Start the loading state
      const data = { userId: selectedUser.id, roleId };

      // Make API request to add the user to the role
      const roles = await apiRequests.addUserToRole(data);

      // Log and handle the returned roles
      console.log("Updated roles:", roles);

      // Optional: Update state or UI with the new roles if needed
      // Example: setSelectedUserRoles(roles);
    } catch (error) {
      console.error("Error adding user to role:", error);
      // Handle the error (e.g., show a notification or alert)
    } finally {
      setIsAddUserRoleLoading(false); // Stop the loading state
    }
  };

  const addUserToBranch = async (branchId) => {
    setIsAddUserBranchLoading(true);
    const data = { userId: selectedUser.id, branchId };
    const roles = await apiRequests.addUserToBranch(data);
    setIsAddUserBranchLoading(false);

    // setIsAllUserLoading(false)
    // setFilteredPendingUsers(formattedUsers);
  };

  const resendVerificationLink = async (data) => {
    // setIsAddUserBranchLoading(true)

    const roles = await apiRequests.resendVerificationLink(data);
    // setIsAddUserBranchLoading(false);

    // setIsAllUserLoading(false)
    // setFilteredPendingUsers(formattedUsers);
  };

  const removeUserFromBranch = async (associationId) => {
    setIsRemoveUserBranchLoading(true); // Show loading indicator

    try {
      const data = {
        userId: selectedUser.id,
        associationId,
      };
      const roles = await apiRequests.removeUserFromBranch(data);

      // if (roles.outData.isSuccessful) {
      // Update selectedUser branches immutably
      const updatedBranches = selectedUser.branches.filter(
        (branch) => branch.associationId !== associationId
      );

      const updatedSelectedUser = {
        ...selectedUser,
        branches: updatedBranches,
      };

      // Find and update pendingUsers immutably
      const updatedVerifiedUsers = verifiedUsers.map((user) =>
        user.id === selectedUser.id
          ? {
              ...user,
              branches: user.branches.filter(
                (branch) => branch.associationId !== associationId
              ),
            }
          : user
      );

      // Update state with new values
      setSelectedUser(updatedSelectedUser);
      setVerifiedUsers(updatedVerifiedUsers);
      // }
    } catch (error) {
      console.error("Error removing user from branch:", error);
    } finally {
      setIsRemoveUserBranchLoading(false); // Hide loading indicator
    }
  };

  const removeUserFromRole = async (associationId) => {
    setIsRemoveUserRoleLoading(true); // Show loading indicator

    try {
      const data = {
        userId: selectedUser.id,
        associationId,
      };
      const roles = await apiRequests.removeUserFromRole(data);

      // if (roles.outData.isSuccessful) {
      // Update selectedUser branches immutably
      const updatedRoles = selectedUser.roles.filter(
        (role) => role.associationId !== associationId
      );

      const updatedSelectedUser = { ...selectedUser, roles: updatedRoles };

      // Find and update pendingUsers immutably
      const updatedVerifiedUsers = verifiedUsers.map((user) =>
        user.id === selectedUser.id
          ? {
              ...user,
              roles: user.roles.filter(
                (role) => role.associationId !== associationId
              ),
            }
          : user
      );

      // Update state with new values
      setSelectedUser(updatedSelectedUser);
      setVerifiedUsers(updatedVerifiedUsers);
      // }
    } catch (error) {
      console.error("Error removing user from branch:", error);
    } finally {
      setIsRemoveUserRoleLoading(false); // Hide loading indicator
    }
  };

  const changeUserState = async (data) => {
    setIsChangingUserStatus(true); // Show loading indicator

    try {
      const response = await apiRequests.changeUserState(data);
      return response;
      // if (roles.outData.isSuccessful) {
      // // Update selectedUser branches immutably
      // const updatedBranches = selectedUser.filter(
      //   (branch) => branch.branchId !== branchId
      // );
      // const updatedSelectedUser = { ...selectedUser, branches: updatedBranches };
      // // Find and update pendingUsers immutably
      // const updatedVerifiedUsers = verifiedUsers.map((user) =>
      //   user.id === selectedUser.id
      //     ? {
      //       ...user,
      //       branches: user.branches.filter(
      //         (branch) => branch.branchId !== branchId
      //       ),
      //     }
      //     : user
      // );
      // Update state with new values
      // setSelectedUser(updatedSelectedUser);
      // setVerifiedUsers(updatedVerifiedUsers);
      // }
    } catch (error) {
      console.error("Error removing user from branch:", error);
    } finally {
      setIsChangingUserStatus(false); // Hide loading indicator
    }
  };

  return (
    <UserContext.Provider
      value={{
        isAllUserLoading,
        fetchPendingUsers,
        fetchActiveUsers,
        selectedUser,
        pendingUsers,
        verifiedUsers,
        changeUserState,
        addUserToRole,
        resendVerificationLink,
        filter,
        setFilter,
        isAddUserBranchLoading,
        addUserToBranch,
        isRemoveUserBranchLoading,
        removeUserFromBranch,
        removeUserFromRole,
        isAddUserRoleLoading,
        corporateProfile,
        allUserRolesQuery,
        approvalInformation,
        isRemoveUserRoleLoading,
        isChangingUserStatus,
        setIsChangingUserStatus,
        activeModal,
        setSelectedUser,
        setActiveModal,
        openModal,
        closeModal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
};
