import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { cleanObject } from "../../../utils/helper/format";

const LoanRequestContext = createContext();

export const LoanRequestProvider = ({ children }) => {
  const triggerNotification = useNotification();
  const [loanRequests, setLoanRequests] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: 50,
    status: "",
    productId: "",
    searchText: "",
    startDate: "",
    endDate: "",
  });

  const getLoanRequests = useCallback(async () => {
    try {
      setIsLoading(true);

      // Filter out properties with empty values
      const cleanedFilter = cleanObject(filter);
      console.log({
        original: filter,
        cleanedFilter,
      });

      const response = await postAuthenticatedRequest(
        "GetLoanQuotesWithFiltersQuery",
        cleanedFilter
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        setLoanRequests(result?.data);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification, filter]);

  return (
    <LoanRequestContext.Provider
      value={{ loanRequests, isLoading, getLoanRequests, filter, setFilter }}
    >
      {children}
    </LoanRequestContext.Provider>
  );
};

export const useLoanRequest = () => {
  return useContext(LoanRequestContext);
};
