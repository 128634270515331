import {
  getSessionData,
  setSessionData,
} from "../../../utils/interceptors/localStorageService";
import { SET_USER_LOGIN, LOGOUT_USER } from "../actions/userActions";

const initialState = {
  isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn")) || false,
  sessionData: getSessionData() || {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_LOGIN:
      localStorage.setItem("isLoggedIn", true);
      setSessionData(action.payload);
      return {
        ...state,
        sessionData: action.payload,
        isLoggedIn: true,
      };
    case LOGOUT_USER:
      localStorage.clear();
      localStorage.setItem("isLoggedIn", false);
      localStorage.setItem("logoutEvent", Date.now());
      return {
        ...state,
        sessionData: {},
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

export default userReducer;
