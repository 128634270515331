// src/store/context/OrganisationContext.js
import React, { createContext, useCallback, useContext, useState } from "react";
import { useNotification } from "../notification-context";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";

// Create context
const OrganisationContext = createContext();

// Custom hook for accessing context
export const useOrganisationContext = () => useContext(OrganisationContext);

export const OrganisationProvider = ({ children }) => {
  const [organisationStructure, setOrganisationStructure] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const triggerNotification = useNotification();

  // API call function to get the organisation structure
  const getOrganisationStructure = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await postAuthenticatedRequest(
        "GetOrganisationStructureQuery"
      );

      const result = response?.outData;
      if (result?.isSuccessful) {
        setOrganisationStructure(result?.data);
      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description: result?.message,
        });
      }
    } catch (error) {
      triggerNotification({
        type: "error",
        message: "Error",
        description: error?.message || "Failed to fetch user data",
      });
    } finally {
      setIsLoading(false);
    }
  }, [triggerNotification]);

  return (
    <OrganisationContext.Provider
      value={{
        organisationStructure,
        isLoading,
        getOrganisationStructure,
      }}
    >
      {children}
    </OrganisationContext.Provider>
  );
};
