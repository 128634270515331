import { NavLink } from "react-router-dom";
import { Modal, Button, Row } from "antd";
import Img from "../../../assets/glaImg.jpg";
import { useTheme } from "../../../store/context/theme-context";

const GroupLifeModal = ({ isModalOpen, onCancel, onOkay, product }) => {
  const { theme } = useTheme();
  const customTitle = (
    <p
      className={`${
        theme === "dark" ? "text-white bg-gray-800" : "text-black"
      } text-lg font-medium text-center font-roboto `}
    >
      Group Life Assurance & WIBA
    </p>
  );

  return (
    <Modal
      title={customTitle}
      open={isModalOpen}
      onOk={onOkay}
      onCancel={onCancel}
      className={theme === "dark" ? "dark-theme" : ""}
      style={{
        width: "741px",
        height: "710px",
        gap: "0px",
        opacity: "1",
      }}
      footer={[
        <Row justify="start" key="footer-row">
          <Button
            key="close"
            type="primary"
            className='h-full px-4 py-1.5 shadow-none text-center'          >
            <NavLink to="group-life-assurance">Apply Now</NavLink>
          </Button>
        </Row>,
      ]}
    >
      <div className="flex flex-col gap-1 ">
        <img
          src={Img}
          alt={product.title}
          className="object-cover text-center rounded-[8px] h-[232px] w-[511px] m-auto"
        />
        <div className="py-3 px-0">
          <p
            className={`${
              theme === "dark" ? "text-white" : "text-[#221F1F]"
            } text-left  font-semibold text-[16px] leading-6`}
          >
            Group Life Asurance:
          </p>
          <p className="text-left font-normal text-[#929497] text-base leading-5 mb-0">
            This cover provides financial protection to employees and their families. 
            It includes a death benefit, accidental death benefit, permanent and temporary disability benefits,
            critical illness cover, funeral expenses, and terminal illness benefit.
          </p>
        </div>
        <div className="py-3 px-0">
          <p
            className={`${
              theme === "dark" ? "text-white" : "text-[#221F1F]"
            } text-left  font-semibold text-[16px] leading-6`}
          >
            Workers' Injury Benefits Act (WIBA):
          </p>
          <p className="text-left font-normal text-[#929497] text-base leading-5 mb-4">
          This cover offers protection for employees against work-related injuries and illnesses. 
          It covers medical expenses, temporary and permanent disablement, death benefits, and funeral expenses.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default GroupLifeModal;
