import React, { createContext, useContext, useState, useEffect } from "react";
import { postAuthenticatedRequest } from "../../../utils/api/requests/request";
import { useNotification } from "../../../store/context/notification-context";
import { formatCurrencyWithCode } from "../../../utils/helper/format";
import { parseCurrency } from "../../../utils/helper/parser";
const LimitManagementContext = createContext();

export const useLimitManagement = () => useContext(LimitManagementContext);

export const LimitManagementProvider = ({ children }) => {
    const [limitSource, setLimitSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState({ account: null, type: null });
    const triggerNotification = useNotification();


    const handleUpdateLimit = async (data, isDaily, callBack) => {
        try {
            setIsUpdating(true);

            const requestData = {
                accountNumber: formData.account,
                limits: Object.entries(data).map(([limitName, limitValue]) => ({
                    transactionType: getTransactionType(limitName),
                    transactionLimit: isDaily ?parseCurrency( limitSource.find((e)=>e.transactionType == getTransactionType(limitName)).transactionLimit) : limitValue,
                    dailyLimit: isDaily ? limitValue :  parseCurrency(limitSource.find((e)=>e.transactionType == getTransactionType(limitName)).dailyLimit)
                }))
            };

            const response = await postAuthenticatedRequest("LimitUpdateRequestCommand", requestData);

            if (response?.outData?.isSuccessful) {
                triggerNotification({
                    type: "success",
                    message: "Success",
                    description: response?.outData?.message || "Limit updated successfully",
                    duration: 5,
                });

                // Refresh the limits after successful update
                await handleLimitRetrieval();
                callBack();
            } else {
                triggerNotification({
                    type: "error",
                    message: "Error",
                    description: response?.outData?.message || "Failed to update limit",
                });
                return false;
            }
        } catch (error) {
            triggerNotification({
                type: "error",
                message: "Error",
                description: error?.toString() || "An error occurred while updating limit",
            });
            return false;
        } finally {
            setIsUpdating(false);
        }
    };

    // Fetch limits when account changes
    const handleLimitRetrieval = async () => {
        try {
            setIsLoading(true);

            const response = await postAuthenticatedRequest("GetAccountLimitQuery", {
                operation: "GET",
                accountNumber: formData.account,
            });

            const result = response?.outData;

            if (result?.isSuccessful) {
                const formattedLimitSource = result.data.accountLimits.map((limit) => ({
                    name: getTransactionTypeName(limit.transactionType),
                    transactionType: limit.transactionType,
                    transactionLimit: formatCurrencyWithCode(limit.defaultLimit.transactionLimit),
                    dailyLimit: formatCurrencyWithCode(limit.defaultLimit.dailyLimit),
                    globalTransactionLimit: formatCurrencyWithCode(limit.globalLimit.transactionLimit),
                    globalDailyLimit: formatCurrencyWithCode(limit.globalLimit.dailyLimit),
                }));

                setLimitSource(formattedLimitSource);

                triggerNotification({
                    type: "success",
                    message: "Success",
                    description: result?.message || "Limits retrieved successfully",
                    duration: 5,
                });
            } else {
                triggerNotification({
                    type: "error",
                    message: "Error",
                    description: result?.message || "An error occurred. Please try again later",
                });
            }
        } catch (error) {
            triggerNotification({
                type: "error",
                message: "Limit Retrieval Failed",
                description: error?.toString() || "An error occurred. Please try again later",
            });
        } finally {
            setIsLoading(false);
        }
    };


    const getTransactionType = (limitName) => {
        switch (limitName) {
            case "limitAirtime":
                return 0; // Airtime
            case "limitData":
                return 1; // Data
            case "limitBill":
                return 2; // Bills
            case "limitTransfer":
                return 3; // Transfer
            default:
                return -1; // Unknown
        }
    };

    const getTransactionTypeName = (type) => {
        switch (type) {
            case 3:
                return "Transfer";
            case 0:
                return "Airtime";
            case 1:
                return "Data";
            case 2:
                return "Bills";
            default:
                return "Unknown";
        }
    };

    const handleSelectChange = (value, name) => {
        setFormData((prevFormData) => {
            const newFormData = { ...prevFormData, [name]: value };

            return newFormData;
        });
    };

    useEffect(() => {
        if (formData.account)
            handleLimitRetrieval();
    }, [formData.account])

    return (
        <LimitManagementContext.Provider
            value={{
                limitSource,
                isLoading,
                handleUpdateLimit,
                formData,
                isUpdating,
                handleSelectChange,
                setFormData,
                handleLimitRetrieval,

            }}
        >
            {children}
        </LimitManagementContext.Provider>
    );
};