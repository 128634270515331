import React, { useRef, useState, useEffect } from "react";
import { Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./styles/Dashboard.css";
import { useTenant } from "../../store/context/tenant-context";



const DashboardCarousel = ({ slides }) => {
  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const [slidesToScroll, setSlidesToScroll] = useState(3);
  const [showArrows, setShowArrows] = useState(true);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1200);
  const tenant = useTenant();

  const DashboardCarouselPrevArrow = ({ onClick }) => (
    <div
    style={{ backgroundColor: tenant.theme.colorPrimary }}
      className='absolute top-1/2 transform -translate-y-1/2 z-10 cursor-pointer w-10 h-10 rounded-full  text-white flex items-center justify-center left-[-12px]'
      onClick={onClick}
    >
      <LeftOutlined className='text-lg' />
    </div>
  );
  
  const DashboardCarouselNextArrow = ({ onClick }) => (
    <div
      className='absolute top-1/2 transform -translate-y-1/2 z-10 cursor-pointer w-10 h-10 rounded-full  text-white flex items-center justify-center right-[-1px]'
      onClick={onClick}
    >
      <RightOutlined className='text-lg' />
    </div>
  );


  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsLargeScreen(width >= 1200);

      let newSlidesToShow = 3;
      let newSlidesToScroll = 3;
      let shouldShowArrows = true;

      if (width < 480) {
        newSlidesToShow = 1;
        newSlidesToScroll = 1;
        shouldShowArrows = true;
      } else if (width < 768) {
        newSlidesToShow = 2;
        newSlidesToScroll = 2;
        shouldShowArrows = true;
      } else if (width < 1200) {
        newSlidesToShow = 2;
        newSlidesToScroll = 2;
        shouldShowArrows = false;
      }

      if (isLargeScreen && slides.length < 3) {
        shouldShowArrows = false;
      }

      setSlidesToShow(newSlidesToShow);
      setSlidesToScroll(newSlidesToScroll);
      setShowArrows(shouldShowArrows);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [slides, isLargeScreen]);

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
      setCurrentIndex(currentIndex - slidesToScroll);
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      setCurrentIndex(currentIndex + slidesToScroll);
    }
  };

  return (
    <div className='relative'>
      {isLargeScreen && slides.length < 3 ? (
        <div className="flex space-x-4">
          {slides.map((slide, slideIndex) => (
            <div key={slideIndex}>
              <div>{slide}</div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Carousel
            ref={carouselRef}
            className={`custom-carousel ${slidesToShow === 3 ? 'space-between' : 'minimal-space'}`}
            draggable={slides.length > slidesToShow}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            arrows={false}
            beforeChange={(current, next) => setCurrentIndex(next)}
          >
            {slides.map((slide, slideIndex) => (
              <div key={slideIndex} style={{
                padding: slidesToShow === 3 ? '0 15px' : '0 5px'
              }}>
                <div>{slide}</div>
              </div>
            ))}
          </Carousel>
          {showArrows && currentIndex > 0 && (
            <DashboardCarouselPrevArrow onClick={handlePrevClick} />
          )}
          {showArrows && currentIndex < slides.length - slidesToShow && (
            <DashboardCarouselNextArrow onClick={handleNextClick} />
          )}
        </>
      )}
    </div>
  );
};

export default DashboardCarousel;
