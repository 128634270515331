import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = "https://sisos-eu.azurewebsites.net/api/cmd";

const initialState = {
  gleData: localStorage.getItem("gleData")
    ? JSON.parse(localStorage.getItem("gleData"))
    : {},
  isLoading: !localStorage.getItem("gleData"),
  isError: false,
};

export const fetchData = createAsyncThunk(
  "funeralExpense/fetchData",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.auth.token;

    if (!token) {
      return thunkAPI.rejectWithValue("No token found");
    }

    // If the gleData already exists in state (from localStorage), skip API call
    if (
      state.funeralExpense.gleData &&
      Object.keys(state.funeralExpense.gleData).length > 0
    ) {
      return state.funeralExpense.gleData;
    }

    try {
      const dataToPost = {
        cmd: "ExeChain",
        data: {
          chain: "M3TrainingGLECalculator",
          context: JSON.stringify(data),
        },
      };
      const response = await axios.post(url, dataToPost, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const gleData = response.data.outData;
      localStorage.setItem("gleData", JSON.stringify(gleData));
      return gleData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const funeralExpenseSlice = createSlice({
  name: "funeralExpense",
  initialState: initialState,
  reducers: {
    resetGleData: (state) => {
      state.isLoading = true;
      state.gleData = {};
      state.isError = false;

      localStorage.removeItem("gleData");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.gleData = action.payload;
      })
      .addCase(fetchData.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { resetGleData } = funeralExpenseSlice.actions;
export const { reducer: funeralExpenseReducer } = funeralExpenseSlice;
