import React, { useState, useEffect } from "react";
import { Form, Input, Button } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "../../store/context/theme-context";
import { useNotification } from "../../store/context/notification-context";
import { useApiRequests } from "../../utils/api/https/executor";
import { getSessionData } from "../../utils/interceptors/localStorageService";
import { SelfUserClassificationEnum } from "../../shared/constants/constants";
import { useTenant } from "../../store/context/tenant-context";

const AdminLogin = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    loginAdmin,
    // other functions...
  } = useApiRequests();
  //const location = useLocation();
  //const isAdmin = location.pathname.startsWith("/admin");

  const { theme } = useTheme();
  const triggerNotification = useNotification();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled = formData.email === "" || formData.password === "";
const tenant = useTenant();
  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const dataToPost = {
        userId: formData.email,
        password: formData.password,
        otpVerificationRequestId: "",
      };

      const response = await    loginAdmin(dataToPost);
      const result = response?.outData;

      if (result.isSuccessful) {
        triggerNotification({
          type: "success",
          message: "Success",
          description: result?.message || "Login successful.",
        });

        const loginContext = JSON.stringify({
          userId: dataToPost.userId,
          emailAddress: formData.email,
          ...result.data,
        });

        navigate("/authentication/otp", {
              state: { loginContext },
            });

      } else {
        triggerNotification({
          type: "error",
          message: "Error",
          description:
            result?.message || "Failed to login. Please try again later.",
        });
      }
    } catch (error) {
      // console.log(error);
      // triggerNotification({
      //   type: "error",
      //   message: "Login Failed",
      //   description:
      //     error?.toString() || "An error occurred. Please try again later",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const sessionData = getSessionData();
    if (sessionData) {
      sessionData?.userClassification === SelfUserClassificationEnum.BACKOFFICE_ADMINISTRATORS.value
        ? navigate("/amdin-dashboard")
        : navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <div className="p-4">
      <div className="mt-16 mb-10">
        <p className="mb-2 text-2xl font-semibold text-left">
          Hello Admin,
        </p>
        <p className="text-[#929497] text-left">
        Welcome to the Admin Portal. Please sign in to manage your account and access administrative features. Ensure your credentials are secure and do not share them with anyone.

        </p>
        
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinishFailed={(event) => console.log(event)}
        onFinish={handleSubmit}
      >
        <div className="grid grid-cols-1 gap-1">
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email address.",
              },
            ]}
          >
            <Input
              placeholder="Enter your email address"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter your password.",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter your password"
              iconRender={(visible) =>
                visible ? (
                  <EyeOutlined  style={{ color: tenant.theme.colorPrimary }} />
                ) : (
                  <EyeInvisibleOutlined />
                )
              }
              value={formData.password}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
            />
          </Form.Item>
        </div>
        <div className="mb-5">
          <button
            type="button"
            onClick={() => navigate("/authentication/forgot-password")}
            className={` ${theme === "dark" ? "hover:bg-stone-800" : "hover:bg-red-50"
              }  flex hover:cursor-pointer items-center h-[40px] w-[180px] bg-transparentborder border-transparent rounded-lg`}
          >
            <span className="text-[16px] ml-2"  style={{ color: tenant.theme.colorPrimary }}>
              Forgot your password?
            </span>
          </button>
        </div>

        <div className="flex flex-col gap-2 mt-4 text-left md:flex-row md:justify-start">
          <Button
            type="primary"
            className="w-full h-full px-3 py-2 text-base text-center shadow-none"
            htmlType="submit"
            disabled={isDisabled}
            loading={isLoading}
          >
            Login
          </Button>
          {/* {!isAdmin && (
            <Button
              className="w-full h-full px-3 py-2 text-center text-baseshadow-none"
              htmlType="button"
              onClick={handleRegister}
            >
              Register
            </Button>
          )} */}
        </div>
      </Form>
    </div>
  );
};

export default AdminLogin;
