import { Navigate, useNavigate } from "react-router-dom";
import InnerLayout from "../../layout/inner-layout/InnerLayout";
import PortalLayout from "../../layout/main-layout/PortalLayout";
import { getSessionData } from "../interceptors/localStorageService";

const ProtectedLayout = ({ element: Component, ...rest }) => {
  const sessionData = getSessionData();
  const navigate = useNavigate();

  const isLoggedIn = !!sessionData;
  const Layout = isLoggedIn ? InnerLayout : PortalLayout;
  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};

export default ProtectedLayout;
