import { Navigate } from "react-router-dom";
import AdminLayout from "../../layout/inner-layout/AdminLayout";
import PortalLayout from "../../layout/main-layout/PortalLayout";
import { getSessionData } from "../interceptors/localStorageService";

const AdminProtectedLayout = ({ element: Component, ...rest }) => {
  const sessionData = getSessionData();
  const isLoggedIn = !!sessionData;

  const Layout = isLoggedIn ? AdminLayout : PortalLayout;

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};

export default AdminProtectedLayout;
