import { Card, Radio, Typography } from "antd";

const { Text } = Typography;

const CountrySupport = ({ country, isSelected, onCardClick }) => {
  return (
    <Card
      bordered
      className={`shadow-none rounded-lg w-full h-full cursor-pointer ${
        isSelected ? "border-[#E5791B] border-1" : ""
      }`}
      onClick={() => onCardClick(country)}
    >
      <Radio.Group
        style={{ width: "100%" }}
        value={isSelected ? country.name : undefined}
      >
        <div className="w-full flex items-center justify-between cursor-pointer">
          <div className="flex w-3/5 items-center">
            <img
              src={country.flag}
              alt={`${country.name} flag`}
              className="w-10 h-10 mr-2 rounded-full"
            />
            <Text className="truncate max-w-full text-base">
              {country.name}
            </Text>
          </div>
          <Radio value={country.name}></Radio>
        </div>
      </Radio.Group>
    </Card>
  );
};

export default CountrySupport;
