import { Form, Input, Select, DatePicker, Checkbox, Row, Col } from "antd";
import { useState } from "react";
import signupTerms from "../../../assets/policy-pdfs/signupTerms.pdf";
import PdfModal from "../../../shared/common/PdfModal";
import { preventNumericInput, validateBirthDate } from "../../../utils/helper/util";

const { Option } = Select;

const Genders = ["Male", "Female"];

const ProfileInfo = ({ form, formData, setFormData }) => {
  const [termsVisible, setTermsVisible] = useState(false);

  const validateTerms = (_, value) => {
    return value
      ? Promise.resolve()
      : Promise.reject(new Error("Please agree to our terms to proceed"));
  };


  return (
    <>
      <div className="mt-4 mb-6">
        <p className="text-2xl font-semibold text-left mb-2">
          Create your profile
        </p>
        <p className="text-left text-[#929497]">
          Please input your personal details
        </p>
      </div>
      <Form form={form} layout="vertical">
        <div className="grid grid-cols-1 gap-1">
          <Form.Item
            label="First Name"
            name="firstName"
            onKeyPress={preventNumericInput}
            rules={[
              {
                required: true,
                message: "Please enter your first name.",
              },
            ]}
          >
            <Input
              placeholder="Enter your first name"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            onKeyPress={preventNumericInput}
            rules={[
              {
                required: true,
                message: "Please enter your last name.",
              },
            ]}
          >
            <Input
              placeholder="Enter your last name"
              value={formData.lastName}
              onChange={(e) =>
                setFormData({ ...formData, lastName: e.target.value })
              }
            />
          </Form.Item>
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Please select a gender." }]}
          >
            <Select
              value={formData.gender}
              onChange={(value) => setFormData({ ...formData, gender: value })}
              placeholder="Please select gender"
            >
              {Genders.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Date of Birth"
            name="birthDate"
            rules={[
              {
                required: true,
                message: "Please select date of birth.",
              },
              { validator: validateBirthDate },
            ]}
            style={{ width: "100%", cursor: "pointer", marginBottom: "35px" }}
          >
            <DatePicker
              style={{ width: "100%" }}
              id="birthDate"
              value={formData.birthDate}
              onChange={(value) => {
                setFormData({ ...formData, birthDate: value });
                // console.log(value);
              }
              }
              // inputReadOnly={true}
            />
          </Form.Item>
        </div>
        <Row>
          <Col>
            <Form.Item
              name="terms"
              valuePropName="checked"
              rules={[
                {
                  validator: validateTerms,
                },
              ]}
              style={{ marginBottom: "35px" }}
            >
              <Checkbox
                checked={formData.terms}
                onChange={(e) =>
                  setFormData({ ...formData, terms: e.target.checked })
                }
              >
                I accept the
                <span
                  style={{ color: "#E5791B", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setTermsVisible(true);
                  }}
                >
                  {" "}
                  terms
                </span>
                {" and "}
                <span
                  style={{ color: "#E5791B", cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.open(
                      "https://equitygroupholdings.com/privacy-policy/",
                      "_blank"
                    );
                  }}
                >
                  privacy policy
                </span>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {termsVisible && (
        <PdfModal
          chosenPdf={signupTerms}
          isVisible={termsVisible}
          onClose={() => setTermsVisible(false)}
        />
      )}
    </>
  );
};

export default ProfileInfo;
